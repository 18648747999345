import { Controller } from "@hotwired/stimulus"
import {
  EVENT_ADDING_EXISTING_DOCUMENT_RECORD,
  EVENT_FILE_ADDED,
  EVENT_FILE_REMOVED,
  FileChangeEventDetail,
} from "../docupload_controller"
import { DropzoneFile } from "dropzone"

type SignedFile = DropzoneFile & { signed_id: string }

// Connects to data-controller="request-form--file-upload"
export default class extends Controller {
  static targets = ["documentsForm", "currentFileSignature"]
  currentFileSignatureTarget: HTMLInputElement
  documentsFormTarget: HTMLFormElement

  connect() {
    window.addEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
  }

  disconnect = (): void => {
    window.removeEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
  }

  handleFileAdded = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = { ...e.detail.file } as unknown as SignedFile

    if (!e.detail.fromSessionStorage) {
      const fileSignature = file.signed_id
      if (this.currentFileSignatureTarget.value === fileSignature) return

      this.currentFileSignatureTarget.value = fileSignature
      this.documentsFormTarget.requestSubmit()
    }
  }

  showLinkedFile(e) {
    const fileListItem = e.currentTarget as HTMLElement
    const fileSignature = fileListItem.dataset.signedId

    this.currentFileSignatureTarget.value = fileSignature
    this.documentsFormTarget.requestSubmit()
  }
}
