import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="workflow-date-trigger-form"
export default class extends Controller {
  static targets = ["offsetDaysField", "conditionOperatorField"]

  offsetDaysFieldTarget: HTMLInputElement
  conditionOperatorFieldTarget: HTMLSelectElement

  connect() {
    this.toggleOffsetDaysFieldRequirement()
  }

  toggleOffsetDaysFieldRequirement = () => {
    const isOn = this.conditionOperatorFieldTarget.value === "on"
    this.updateOffsetDaysFieldState(isOn)
  }

  updateOffsetDaysFieldState(isDisabled) {
    this.offsetDaysFieldTarget.value = isDisabled ? "" : this.offsetDaysFieldTarget.value
    this.offsetDaysFieldTarget.required = !isDisabled
    this.offsetDaysFieldTarget.disabled = isDisabled
    this.offsetDaysFieldTarget.placeholder = isDisabled ? "" : "5"

    this.element
      .querySelector("#workflow_date_trigger_offset_days_wrapper span.required")
      ?.classList.toggle("hidden", isDisabled)
  }
}
