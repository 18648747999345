import { Controller } from "@hotwired/stimulus"

const VALIDATION_ERROR_CLASSES = ["border-red-500"]

// Connects to data-controller="request-form--address"
export default class extends Controller {
  static targets = ["requiredField", "validationField"]

  static values = {
    required: { type: Boolean, default: false },
  }

  requiredFieldTargets: HTMLInputElement[]
  validationFieldTarget: HTMLInputElement

  hasRequiredFieldTarget: boolean

  requiredValue: boolean

  onValidationErrorHandler = this.onValidationError.bind(this)
  onOtherValidationErrorsHandler = this.onOtherValidationErrors.bind(this)

  connect() {
    this.updateFormValidity()
    if (this.hasRequiredFieldTarget) {
      document.addEventListener("invalid", this.onOtherValidationErrorsHandler, true)
      this.validationFieldTarget.addEventListener("invalid", this.onValidationErrorHandler)
    }
  }

  disconnect() {
    if (this.hasRequiredFieldTarget) {
      document.removeEventListener("invalid", this.onOtherValidationErrorsHandler, true)
      this.validationFieldTarget.removeEventListener("invalid", this.onValidationErrorHandler)
    }
  }

  onInputChange(e) {
    this.updateFormValidity()
  }

  private onValidationError(e) {
    this.highlightInvalidFields()
  }

  // If there are errors in other questions, resets the highlighted fields
  private onOtherValidationErrors(e) {
    this.highlightInvalidFields()
  }

  private highlightInvalidFields() {
    if (!this.hasRequiredFieldTarget) {
      return
    }

    this.requiredFieldTargets.forEach((input) => {
      if (!input.value.trim()) {
        VALIDATION_ERROR_CLASSES.forEach((css) => input.classList.add(css))
      } else {
        VALIDATION_ERROR_CLASSES.forEach((css) => input.classList.remove(css))
      }
    })
  }

  // Updates the validation input validity
  private updateFormValidity() {
    if (this.requiredValue && this.hasRequiredFieldTarget) {
      const allValid = this.requiredFieldTargets.every((input) => !!input.value.trim())
      this.validationFieldTarget.value = allValid ? "valid" : ""
    } else {
      this.validationFieldTarget.value = "valid"
    }
  }
}
