import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="empty-field"
export default class extends Controller {
  static targets = ["field"]

  fieldTarget: HTMLInputElement

  onChangeFieldTargetFunction: (event: any) => void

  connect() {
    this.checkValue(this.fieldTarget?.value || "")
    this.onChangeFieldTargetFunction = (e: any) => this.checkValue(e.target.value)
    this.fieldTarget.addEventListener("change", this.onChangeFieldTargetFunction)
  }

  disconnect() {
    this.fieldTarget.removeEventListener("change", this.onChangeFieldTargetFunction)
  }

  checkValue(value) {
    if (value === "") {
      this.fieldTarget.classList.add("is-empty")
    } else {
      this.fieldTarget.classList.remove("is-empty")
    }
  }
}
