import { Controller } from "@hotwired/stimulus"

/* Connects to data-controller="canopy--editor-preview-component"

  This controller is used to accept or reject AI suggestions for General Strategy on
  Supplier Negotiation Intelligence records.

 */
export default class extends Controller {
  static targets = ["currentVersionContainer", "input", "separatorContainer", "suggestedContainer", "suggestedContent"]
  currentVersionContainerTarget: HTMLElement
  inputTarget: HTMLInputElement
  separatorContainerTarget: HTMLElement
  suggestedContainerTarget: HTMLElement
  suggestedContentTarget: HTMLElement

  acceptSuggestion(e) {
    this.inputTarget.value = this.suggestedContentTarget.innerHTML
    this.hideSuggestion()
  }

  rejectSuggestion(e) {
    this.suggestedContentTarget.innerHTML = "✌️"
    this.hideSuggestion()
  }

  hideSuggestion() {
    this.currentVersionContainerTarget.classList.add("animate-fade-out-opacity")
    this.separatorContainerTarget.classList.add("animate-fade-out-opacity")
    this.suggestedContainerTarget.classList.add("animate-fade-out-opacity")

    setTimeout(() => {
      this.currentVersionContainerTarget.remove()
      this.separatorContainerTarget.remove()
      this.suggestedContainerTarget.remove()
    }, 800)
  }
}
