import { Controller } from "@hotwired/stimulus"

type ClassNames = {
  open: string
  toucan: string
  animate: string
}

export default class ProfileMenuController extends Controller {
  static targets = ["profileMenuContainer", "profileAvatarContainer", "profileLauncher"]

  // Static classes to define CSS class names for the controller
  static classes: ClassNames = {
    open: "profile-menu-open",
    toucan: "canopy-profile-menu--toucan",
    animate: "canopy-profile-menu--animate",
  }

  profileMenuContainerTarget!: HTMLElement
  profileAvatarContainerTarget!: HTMLElement
  profileLauncherTarget!: HTMLElement

  connect(): void {
    this.profileLauncherTarget?.addEventListener("click", this.toggle)
    document.addEventListener("click", this.outsideClickListener)
  }

  disconnect(): void {
    document.removeEventListener("click", this.outsideClickListener)
    this.profileLauncherTarget?.removeEventListener("click", this.toggle)
  }

  toggle = (): void => {
    this.profileLauncherTarget.classList.toggle((this.constructor as typeof ProfileMenuController).classes.open)
    // Add animation if the menu is open, else reset it
    if (
      this.profileLauncherTarget.classList.contains((this.constructor as typeof ProfileMenuController).classes.open)
    ) {
      this.addAnimation()
    } else {
      this.resetAnimation()
    }
  }

  // Add animation to the menu
  private addAnimation = (): void => {
    this.profileAvatarContainerTarget.classList.add((this.constructor as typeof ProfileMenuController).classes.toucan)
    // Delay adding the animation class for smooth transition
    setTimeout(() => {
      this.profileMenuContainerTarget.classList.add((this.constructor as typeof ProfileMenuController).classes.animate)
    }, 300)
  }

  // Reset the animation and revert the menu to its initial state
  private resetAnimation = (): void => {
    this.profileAvatarContainerTarget.classList.remove(
      (this.constructor as typeof ProfileMenuController).classes.toucan,
    )
    this.profileMenuContainerTarget.classList.remove((this.constructor as typeof ProfileMenuController).classes.animate)
  }

  private outsideClickListener = (event: MouseEvent): void => {
    if (
      !this.profileMenuContainerTarget.contains(event.target as Node) &&
      !this.profileLauncherTarget.contains(event.target as Node)
    ) {
      this.profileLauncherTarget.classList.remove((this.constructor as typeof ProfileMenuController).classes.open)
      this.resetAnimation()
    }
  }
}
