import { Controller } from "@hotwired/stimulus"
import { intersection } from "lodash"
import { show, hide, setRequired, removeRequired } from "../../utils"

const ORGANIZATION_ROLE = "OrganizationRole"
const DEPARTMENT_ROLE = "DepartmentRole"

// Connects to data-controller="settings--multi-role"
export default class extends Controller {
  static targets = ["multiRoleSelect", "staffRoleSelect", "form", "submitButton"]
  static values = {
    organizationId: String,
    defaultOrganizationRole: String,
  }

  multiRoleSelectTarget: HTMLElement
  staffRoleSelectTarget: HTMLElement
  formTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement

  hasMultiRoleSelectTarget: boolean
  hasStaffRoleSelectTarget: boolean

  organizationIdValue: string
  defaultOrganizationRoleValue: string

  onInitializeFunction: (event) => void
  onChangeFunction: (event) => void
  onDropdownOpenFunction: (event) => void

  connect() {
    this.onInitializeFunction = this.onInitialize.bind(this)
    this.onChangeFunction = this.onChange.bind(this)
    this.onDropdownOpenFunction = this.onDropdownOpen.bind(this)

    window.addEventListener("StyledSelect:initialize", this.onInitializeFunction)
    window.addEventListener("StyledSelect:valueChanged", this.onChangeFunction)
    window.addEventListener("StyledSelect:dropdownOpened", this.onDropdownOpenFunction)
  }

  disconnect() {
    window.removeEventListener("StyledSelect:initialize", this.onInitializeFunction)
    window.removeEventListener("StyledSelect:valueChanged", this.onChangeFunction)
    window.removeEventListener("StyledSelect:dropdownOpened", this.onDropdownOpenFunction)
  }

  submit() {
    if (!this.hasMultiRoleSelectTarget) {
      return
    }

    event.preventDefault()

    const roleAttributes = this.buildRoleAttributes()
    this.clearRoleAttributesFields()
    this.addRoleAttributesToForm(roleAttributes)
    this.updateStaffRoleParams(roleAttributes.length)

    this.submitButtonTarget.click()
  }

  clear() {
    const tomselect = this.multiRoleSelectTarget.tomselect

    if (tomselect) {
      tomselect.clear()
      this.refreshRoleOptions(tomselect)
      this.refreshDepartments(tomselect)
    }
  }

  private onInitialize(event): void {
    const origin = event.detail.origin
    this.refreshRoleOptions(origin.tomselect)
    this.refreshDepartments(origin.tomselect)
  }

  private onChange(event): void {
    const origin = event.detail.origin
    this.refreshRoleOptions(origin.tomselect)
    this.refreshDepartments(origin.tomselect)
  }

  private onDropdownOpen(event): void {
    const { origin, container } = event.detail
    let tsWrapper = container.getElementsByClassName("ts-wrapper plugin-checkbox_options")[0]

    if (!tsWrapper) {
      return
    }

    let tsDropdown = tsWrapper.getElementsByClassName("ts-dropdown")[0]
    let tsDropdownContent = tsDropdown.getElementsByClassName("ts-dropdown-content")[0]

    tsDropdown.setAttribute("style", "display: flex; visibility: visible;")
    tsDropdownContent.classList.add("w-1/2", "pb-2")
    this.refreshRoleOptions(origin.tomselect)
  }

  private refreshRoleOptions(tomSelect): void {
    let options = Object.values(tomSelect.options)
    let optionGroupsToDisable = []

    if (this.hasSelectedOrganizationRoles(tomSelect)) {
      optionGroupsToDisable = [ORGANIZATION_ROLE, DEPARTMENT_ROLE]
    } else if (this.hasSelectedDepartmentRoles(tomSelect)) {
      optionGroupsToDisable = [ORGANIZATION_ROLE]
    }

    options.forEach((option) => {
      const isSelected = tomSelect.items.includes(option.value)
      const disabled = optionGroupsToDisable.includes(option.optgroup)

      if (!isSelected) {
        tomSelect.updateOption(option.value, { ...option, disabled: disabled })
      }
    })
  }

  private refreshDepartments(tomSelect): void {
    const departmentRoles = this.roles(tomSelect, DEPARTMENT_ROLE)

    departmentRoles.forEach((role) => {
      const selectWrapper = document.getElementById(role + "_departments")
      const select = document.getElementById(role)

      if (selectWrapper) {
        if (tomSelect.items.includes(role)) {
          show(selectWrapper)
          setRequired(select)
        } else {
          hide(selectWrapper)
          removeRequired(select)
        }
      }
    })
  }

  private hasSelectedOrganizationRoles(tomSelect): boolean {
    return this.hasSelectedRoles(tomSelect, ORGANIZATION_ROLE)
  }

  private hasSelectedDepartmentRoles(tomSelect): boolean {
    return this.hasSelectedRoles(tomSelect, DEPARTMENT_ROLE)
  }

  private hasSelectedRoles(tomSelect, roleType): boolean {
    const selectedItems = tomSelect.items
    const roles = this.roles(tomSelect, roleType)

    return intersection(selectedItems, roles).length > 0
  }

  private roles(tomSelect, roleType): string[] {
    const options = Object.values(tomSelect.options)
    const roleTypeOptions = options.filter((option) => option.optgroup === roleType)

    return roleTypeOptions.map((option) => option.value)
  }

  private buildRoleAttributes() {
    const tomSelect = this.multiRoleSelectTarget.tomselect
    const selectedRoles = tomSelect.items
    let attributes = []

    if (this.hasSelectedOrganizationRoles(tomSelect)) {
      selectedRoles.forEach((role) => attributes.push(this.roleAttributes(role)))
    } else if (this.hasSelectedDepartmentRoles(tomSelect)) {
      selectedRoles.forEach((role) => {
        const departments = this.selectedDepartmentsForRole(role)
        departments.forEach((item) => attributes.push(this.roleAttributes(role, item)))
      })
    } else {
      attributes = this.defaultOrganizationRoleAttributes()
    }

    return attributes
  }

  private updateStaffRoleParams(index) {
    if (this.hasStaffRoleSelectTarget) {
      const name = this.staffRoleSelectTarget.name.replace(/[0-9]/, index)

      this.staffRoleSelectTarget.setAttribute("name", name)
    }
  }

  private addRoleAttributesToForm(roleAttributes): void {
    roleAttributes.forEach((attributes, index) => {
      this.appendRoleAttributeField("organization_id", attributes.organization_id, index)
      this.appendRoleAttributeField("key", attributes.key, index)
      this.appendRoleAttributeField("department_id", attributes.department_id, index)
    })
  }

  private appendRoleAttributeField(key, value, index): void {
    const input = document.createElement("input")
    input.type = "hidden"
    input.multiple = true
    input.name = `user[role_attribute][${index}][${key}]`
    input.value = value

    this.formTarget.appendChild(input)
  }

  private selectedDepartmentsForRole(role) {
    const depSelect = document.getElementById(role)

    if (depSelect) {
      return depSelect.tomselect.items
    } else {
      return []
    }
  }

  private defaultOrganizationRoleAttributes() {
    return [this.roleAttributes(this.defaultOrganizationRoleValue)]
  }

  private roleAttributes(key, departmentId = null) {
    return { organization_id: this.organizationIdValue, key: key, department_id: departmentId }
  }

  private clearRoleAttributesFields(): void {
    this.formTarget.querySelectorAll('input[name^="user[role_attribute]"]').forEach((input) => input.remove())
  }
}
