import { Controller } from "@hotwired/stimulus"
import { EventListener } from "@hotwired/stimulus/dist/types/core/event_listener"
import { standardDateFormat } from "../utils/dates"
import { FileStatus } from "../utils/constants"
import { hide, show } from "../utils"
import { DropzoneFile } from "dropzone"

type SignedFile = File & { signed_id: string }

export type FileWithDocumentRecord = SignedFile & {
  metadata: {
    document_record: {
      created_at: string
      linked_to_contract: boolean
      uploaded_by_id: string
      uploaded_by_user_name: string
    }
  }
}

export type CustomAction = {
  action: (f: SignedFile) => void
  icon: string
  label: string
}

type StandardActions = {
  onRemove: (f: SignedFile) => void
}

export type UpsertFileParams = {
  customActions: CustomAction[]
  file: SignedFile
  status: FileStatus
  statusLabel?: string
  uniqueId: string
} & StandardActions

const REMOVE_BUTTON_SELECTOR = ".document-list-item__remove-button"
const DOCUMENT_LIST_ITEM_TEMP_ID_FOR_NEW_FILE = "document-list-item-new-PLACEHOLDER-FOR-FILE-SIGNATURE"

// Connects to data-controller="document-list-component"
export default class extends Controller {
  static targets = [
    "documentListItemCustomActionTemplate",
    "documentListItemTemplate",
    "initialDocumentListItem",
    "list",
  ]

  static values = {
    skipRemoveButtonDefaultHandler: {
      default: false,
      type: Boolean,
    },
  }

  documentIdToDocumentListItemMap = new Map<String, HTMLElement>()
  documentListItemCustomActionTemplateTarget!: HTMLTemplateElement
  documentListItemTemplateTarget!: HTMLTemplateElement
  eventListenersMap = new Map<HTMLElement, EventListener>()
  initialDocumentListItemTargets!: HTMLDivElement[]
  listTarget!: HTMLDivElement
  skipRemoveButtonDefaultHandlerValue: boolean

  cleanUpEventListeners = () => {
    this.eventListenersMap.forEach((eventListener, element) => {
      element.removeEventListener("click", eventListener)
      this.eventListenersMap.delete(element)
    })
  }

  clonedListItem = (): HTMLElement => {
    return this.documentListItemTemplateTarget.content.cloneNode(true).children[0]
  }

  disconnect = () => {
    this.cleanUpEventListeners()
  }

  findOrCreateElement = (uniqueId: string): HTMLElement => {
    const existingDocumentListItem = this.documentIdToDocumentListItemMap.get(uniqueId)
    if (existingDocumentListItem) return existingDocumentListItem

    const newListItem = this.clonedListItem()
    this.listTarget.appendChild(newListItem)
    return newListItem
  }

  initialDocumentListItemTargetConnected = (item: HTMLDivElement) => {
    const fileInfoInput = item.querySelector("input.document-list-item__document-id-input")
    if (!fileInfoInput) return

    this.documentIdToDocumentListItemMap.set(fileInfoInput.value, item)
    this.upsertFile({
      file: JSON.parse(fileInfoInput.dataset.fileInfo),
      onRemove: () => {},
      status: FileStatus.SUCCESS,
      uniqueId: fileInfoInput.value,
    })
  }

  setCustomActions = (dli: HTMLElement, file: SignedFile, cas: CustomAction[]) => {
    const customActionsContainer = dli.querySelector(".document-list-item__custom-actions")!
    customActionsContainer.replaceChildren([])

    const actionButtons = cas.map((ca: CustomAction): HTMLElement => {
      const clonedActionButton: HTMLElement =
        this.documentListItemCustomActionTemplateTarget.content.cloneNode(true).children[0]
      const iconElement = clonedActionButton.querySelector(".document-list-component__custom-action-icon")!
      const labelElement = clonedActionButton.querySelector(".label")!

      iconElement.classList.remove("fa-PLACEHOLDER")
      iconElement.classList.add(ca.icon)
      labelElement.textContent = ca.label

      const eventListener = (e: Event) => {
        e.stopPropagation()
        ca.action(file)
      }

      // false-positive from eslint, listener cleaned up in a loop
      // eslint-disable-next-line listeners/no-missing-remove-event-listener
      clonedActionButton.addEventListener("click", eventListener, { capture: true })
      this.eventListenersMap.set(clonedActionButton, eventListener)

      return clonedActionButton
    })

    customActionsContainer.replaceChildren(...actionButtons)
  }

  setDocumentAttributes = (dli: HTMLElement, file: FileWithDocumentRecord) => {
    if (!file.metadata?.document_record) return

    const uploaderNameElement = dli.querySelector("span[data-attribute-name='uploaded_by_user_name']")
    const uploadDateElement = dli.querySelector("span[data-attribute-name='created_at']")
    const linkedToContractElement = dli.querySelector("span[data-attribute-name='linked_to_contract']")

    uploaderNameElement!.textContent = file.metadata.document_record.uploaded_by_user_name
    uploadDateElement!.textContent = standardDateFormat(new Date(file.metadata.document_record.created_at))
    linkedToContractElement!.textContent = file.metadata.document_record.linked_to_contract ? "Contract" : "Not Set"
  }

  setFileName = (dli: HTMLElement, fileName: string) => {
    dli.querySelector("div[data-attribute-name='document_name']")!.textContent = fileName
  }

  setSignedIdAsDomIdForNewFiles = (dli: HTMLElement, file: SignedFile) => {
    const notNewFile = dli.getAttribute("id") !== DOCUMENT_LIST_ITEM_TEMP_ID_FOR_NEW_FILE
    if (notNewFile || !file.signed_id) return

    dli.setAttribute("id", `document-list-item-new-${file.signed_id}`)
  }

  setStandardActions = (dli: HTMLElement, file: SignedFile, sa: StandardActions) => {
    const removeButton = dli.querySelector(REMOVE_BUTTON_SELECTOR) as HTMLButtonElement
    if (!removeButton || this.skipRemoveButtonDefaultHandlerValue) return

    const eventListener = (e: Event) => {
      e.stopPropagation()
      sa.onRemove(file)
      dli.remove()
    }
    // false-positive from eslint, listener cleaned up in a loop
    // eslint-disable-next-line listeners/no-missing-remove-event-listener
    removeButton.addEventListener("click", eventListener, { capture: true })
    this.eventListenersMap.set(removeButton, eventListener)
  }

  setUniqueIdentifiers = (dli: HTMLElement, uniqueId: string, file: SignedFile) => {
    dli.dataset.uniqueId = uniqueId
    dli.dataset.signedId = file.signed_id
  }

  triggerListItemRemoval = (e: CustomEvent) => {
    const removeButton = e.target as HTMLButtonElement
    const dli = removeButton.closest(".document-list-item") as HTMLElement

    this.documentIdToDocumentListItemMap.forEach((item, key) => {
      if (item === dli) {
        this.documentIdToDocumentListItemMap.delete(key)
      }
    })

    dli.remove()
  }

  updateDocumentIconState = (dli: HTMLElement, status: FileStatus) => {
    const documentIcon = dli.querySelector(".document-list-item__document-icon")

    if (status === FileStatus.SUCCESS) {
      show(documentIcon)
    } else {
      hide(documentIcon)
    }
  }

  updateErrorState = (dli: HTMLElement, status: FileStatus, statusLabel: string) => {
    const errorIcon = dli.querySelector(".document-list-item__error-icon")
    const statusLabelElement = dli.querySelector(".document-list-item__status-label")

    if (status === FileStatus.ERROR) {
      show(errorIcon)
      show(statusLabelElement)
      statusLabelElement.textContent = statusLabel
    } else {
      hide(errorIcon)
      hide(statusLabelElement)
    }
  }

  updateLoadingState = (dli: HTMLElement, status: FileStatus) => {
    const spinner = dli.querySelector(".document-list-item__loading-spinner")
    if (status === FileStatus.LOADING) {
      show(spinner)
    } else {
      hide(spinner)
    }
  }

  upsertFile = ({ customActions = [], file, onRemove, status, statusLabel, uniqueId }: UpsertFileParams) => {
    const documentListItem = this.findOrCreateElement(uniqueId)
    this.setFileName(documentListItem, file.name)
    if ((file as FileWithDocumentRecord).metadata?.document_record) {
      this.setDocumentAttributes(documentListItem, file as FileWithDocumentRecord)
    } else {
      this.setSignedIdAsDomIdForNewFiles(documentListItem, file)
    }
    this.setUniqueIdentifiers(documentListItem, uniqueId, file)
    this.updateDocumentIconState(documentListItem, status)
    this.updateErrorState(documentListItem, status, statusLabel)
    this.updateLoadingState(documentListItem, status)
    this.setCustomActions(documentListItem, file, customActions)
    this.setStandardActions(documentListItem, file, {
      onRemove: () => {
        onRemove(file)
        // Extend the remove callback to clean up the document list item map. Otherwise, the map will keep the
        // uniqueId reference even tho the element is not in the DOM anymore
        this.documentIdToDocumentListItemMap.delete(uniqueId)
      },
    })
    this.documentIdToDocumentListItemMap.set(uniqueId, documentListItem)
  }
}
