import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"
import { get } from "@rails/request.js"
import { buildUrl } from "../utils/urls"

// Connects to data-controller="nonlinear-task-rule-field"
export default class extends Controller {
  static outlets = ["nonlinear-task-rules-form"]
  static values = {
    changeQuestionPath: String,
  }
  changeQuestionPathValue: string

  static targets = [
    "answerField",
    "amountCentsRuleField",
    "ruleEndValue",
    "ruleEndValueAnd",
    "comparator",
    "questionField",
    "requestFormQuestionRuleField",
    "ruleFieldWrapper",
    "ruleField",
  ]
  answerFieldTarget: HTMLElement
  hasAnswerFieldTarget: Boolean
  amountCentsRuleFieldTarget: HTMLDivElement
  ruleEndValueTarget: HTMLInputElement
  ruleEndValueAndTarget: HTMLInputElement
  comparatorTarget: HTMLInputElement
  questionFieldTarget: HTMLElement
  hasQuestionFieldTarget: HTMLElement
  requestFormQuestionRuleFieldTarget: HTMLInputElement
  ruleFieldTarget: HTMLInputElement
  ruleFieldWrapperTarget: HTMLFormElement

  connect() {
    this.toggleRules(this.nonlinearTaskRulesFormOutlet.rulesEnabled())
  }

  toggleRules(rulesEnabled): void {
    if (rulesEnabled) {
      this.toggleRuleFields(true)
      this.onRuleFieldChange(true)
    } else {
      this.toggleRuleFields(false)
      this.makeRequestFormQuestionRuleFieldsNotRequired()
    }

    this.renderRulesFields()
    this.onRuleFieldChange(rulesEnabled)

    show(this.selectedRuleField(this.ruleFieldTarget.value))
    if (this.ruleFieldTarget.value !== "request_form_question") {
      this.makeRequestFormQuestionRuleFieldsNotRequired()
    }
  }

  onRuleQuestionChange(e: Event): void {
    const queryParams = {
      form_field_id: e.target.value,
    }

    const url = buildUrl(this.changeQuestionPathValue, queryParams)
    get(url, { responseKind: "turbo-stream" })
  }

  onComparatorChange(): void {
    this.renderRulesFields()
  }

  onRuleFieldChange(rulesEnabled = true): void {
    if (!rulesEnabled) return

    const selectedRuleField = this.selectedRuleField(this.ruleFieldTarget.value)
    const ruleFieldTypes = Object.keys(this.ruleFieldTypeTargetMap())

    ruleFieldTypes.forEach((ruleFieldType) => {
      if (ruleFieldType != this.ruleFieldType(this.ruleFieldTarget.value)) {
        const field = this.ruleFieldTypeTargetMap()[ruleFieldType]
        hide(field)
        this.toggleInputFields(false, field)
      } else {
        show(selectedRuleField)
        this.toggleInputFields(true, selectedRuleField)
      }
    })

    if (this.ruleFieldTarget.value == "request_form_question") {
      this.makeRequestFormQuestionRuleFieldsRequired()
    } else {
      this.makeRequestFormQuestionRuleFieldsNotRequired()
    }
  }

  private selectedRuleField(ruleField): HTMLDivElement {
    const ruleFieldType = this.ruleFieldType(ruleField)
    return this.ruleFieldTypeTargetMap()[ruleFieldType]
  }

  private ruleFieldTypeTargetMap() {
    return {
      amount_cents: this.amountCentsRuleFieldTarget,
      request_form_question: this.requestFormQuestionRuleFieldTarget,
    }
  }

  private ruleFieldType(ruleField): string {
    if (ruleField === "budget_cents" || ruleField === "bill_total_amount_cents") {
      return "amount_cents"
    } else if (ruleField === "request_form_question") {
      return "request_form_question"
    }
  }

  private toggleRuleFields(enabled: Boolean): void {
    this.toggleInputFields(enabled, this.ruleFieldWrapperTarget)
  }

  private toggleInputFields(enabled: Boolean, container: any): void {
    const inputs = container?.querySelectorAll("input") || []

    inputs.forEach((input: HTMLInputElement) => {
      input.disabled = !enabled
    })
  }

  private renderRulesFields(): void {
    const comparator = this.comparatorTarget.value
    if (comparator === "between") {
      show(this.ruleEndValueTarget)
      show(this.ruleEndValueAndTarget)
    } else {
      hide(this.ruleEndValueTarget)
      hide(this.ruleEndValueAndTarget)
    }
  }

  private makeRequestFormQuestionRuleFieldsRequired(): void {
    if (this.hasAnswerFieldTarget) {
      this.answerFieldTarget.setAttribute("required", "")
    }
    if (this.hasQuestionFieldTarget) {
      this.questionFieldTarget.setAttribute("required", "")
    }
  }

  private makeRequestFormQuestionRuleFieldsNotRequired(): void {
    if (this.hasAnswerFieldTarget) {
      this.answerFieldTarget.removeAttribute("required")
    }
    if (this.hasQuestionFieldTarget) {
      this.questionFieldTarget.removeAttribute("required")
    }
  }
}
