import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  animationValue: number
  resourceNameValue: string
  paramNameValue: string
  responseKindValue: string
  sortables: Sortable[]
  handleValue: string
  // @ts-ignore
  element: HTMLElement

  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: "position",
    },
    responseKind: {
      type: String,
      default: "html",
    },
    animation: Number,
    handle: String,
  }

  dragEnterFunction: () => void
  dragStartFunction: () => void

  initialize() {
    this.canOpenGroup = false
    this.end = this.end.bind(this)
  }

  connect() {
    this.dragEnterFunction = this.dragEnter.bind(this)
    this.dragStartFunction = this.dragStart.bind(this)

    document.addEventListener("dragenter", this.dragEnterFunction)
    document.addEventListener("dragstart", this.dragStartFunction)

    this.sortables = []

    const sortableLists = document.querySelectorAll(".sortable-list")
    for (let i = 0; i < sortableLists.length; i++) {
      const sortable = new Sortable(sortableLists[i], {
        ...this.defaultOptions,
        ...this.options(sortableLists[i]),
      })

      this.sortables.push(sortable)
    }
  }

  disconnect() {
    document.removeEventListener("dragenter", this.dragEnterFunction)
    document.removeEventListener("dragstart", this.dragStartFunction)

    this.sortables.forEach((sortable) => sortable.destroy())
    this.sortables = []
  }

  dragEnter(event) {
    const toElement = event.toElement

    if (this.canOpenGroup && toElement.classList.contains("group-container")) {
      toElement.dataset.collapseIsOpenValue = true
    }
  }

  dragStart(event) {
    this.canOpenGroup = Boolean(event.target?.id?.includes("approval-task"))
    this.showNoTasksContainer(event)
  }

  async end(event) {
    const { item, newIndex } = event

    if (!item.dataset.sortableUpdateUrl) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.paramNameValue}]` : this.paramNameValue

    const data = new FormData()
    data.append(param, newIndex + 1)

    if (item.id.includes("approval-task")) {
      const groupContainer = this.getGroupContainer(item)
      data.append("new_approval_group_id", groupContainer.id.replace("approval-group-", ""))

      this.hideNoTasksContainer(item)
    }

    await patch(item.dataset.sortableUpdateUrl, { body: data, responseKind: this.responseKindValue })
  }

  getGroupContainer(item) {
    return item.parentElement.parentElement.parentElement
  }

  hideNoTasksContainer(item) {
    const noTasksContainer = item.parentElement.nextElementSibling
    const addTaskLink = noTasksContainer.nextElementSibling

    if (addTaskLink && noTasksContainer) {
      noTasksContainer.classList.add("hidden")
      addTaskLink.classList.remove("hidden")
    }
  }

  showNoTasksContainer(event) {
    const prevElement = event.srcElement.previousElementSibling
    const nextElement = event.srcElement.nextElementSibling
    const noTasksContainer = event.srcElement.parentElement.nextElementSibling
    const addTaskLink = noTasksContainer.nextElementSibling

    if (prevElement?.id.includes("approval-task") || nextElement?.id.includes("approval-task")) {
      return
    }

    if (addTaskLink && noTasksContainer?.classList.contains("hidden")) {
      noTasksContainer.classList.remove("hidden")
      addTaskLink.classList.add("hidden")
    }
  }

  options(sortableListElement): Sortable.Options {
    const groupName = sortableListElement.dataset.sortableGroupName

    return {
      draggable: ".draggable",
      filter: ".ignore-sortable",
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onEnd: this.end,
      group: {
        name: groupName,
        pull: groupName,
        put: groupName,
      },
    }
  }

  get defaultOptions(): Sortable.Options {
    return {}
  }
}
