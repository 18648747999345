import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="assistant"
export default class extends Controller {
  static targets = ["form", "initialAiMessage", "messageInput", "msgField", "clearChatButton", "submitButton"]
  formTarget: HTMLFormElement
  initialAiMessageTargets: HTMLDivElement[]
  messageInputTarget: HTMLInputElement
  msgFieldTarget: HTMLInputElement
  clearChatButtonTarget: HTMLAnchorElement
  submitButtonTarget: HTMLButtonElement
  hasClearChatButtonTarget: boolean

  DISABLED_SUBMIT_BUTTON_CLASSES = ["bg-gray-100", "hover:bg-gray-200", "active:bg-gray-200", "text-gray-600"]
  ENABLED_SUBMIT_BUTTON_CLASSES = ["bg-purple-500", "hover:bg-purple-600", "active:bg-purple-700", "text-white"]

  onTurboRenderFunc = this.onTurboRender.bind(this)

  connect() {
    this.formTarget.addEventListener("submit", this.onSubmit)
    this.messageInputTarget.addEventListener("input", this.toggleSubmitButton)
    this.toggleSubmitButton()

    document.addEventListener("turbo:render", this.onTurboRenderFunc)
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.onSubmit)
    this.messageInputTarget.removeEventListener("input", this.toggleSubmitButton)
    document.removeEventListener("turbo:render", this.onTurboRenderFunc)
  }

  onTurboRender() {
    this.messageInputTarget.value = ""
    this.toggleSubmitButton()
  }

  onSubmit = (event) => {
    event.preventDefault()
    this.initialAiMessageTargets.forEach((element) => {
      hide(element)
    })

    if (this.hasClearChatButtonTarget) {
      show(this.clearChatButtonTarget)
    }

    const message = this.messageInputTarget.value.trim()

    if (message) {
      this.messageInputTarget.value = ""
      this.msgFieldTarget.value = message
      this.formTarget.submit()
      this.toggleSubmitButton()
    }
  }

  toggleSubmitButton = () => {
    if (!this.hasSubmitButtonTarget) return

    const message = this.messageInputTarget.value.trim()
    const isEnabled = Boolean(message)

    this.submitButtonTarget.disabled = !isEnabled

    const addClasses = isEnabled ? this.ENABLED_SUBMIT_BUTTON_CLASSES : this.DISABLED_SUBMIT_BUTTON_CLASSES
    const removeClasses = isEnabled ? this.DISABLED_SUBMIT_BUTTON_CLASSES : this.ENABLED_SUBMIT_BUTTON_CLASSES

    removeClasses.forEach((className) => this.submitButtonTarget.classList.remove(className))
    addClasses.forEach((className) => this.submitButtonTarget.classList.add(className))
  }

  triggerExampleQuestion = (event) => {
    event.preventDefault()
    const questionText = event.currentTarget.lastElementChild.innerText
    this.messageInputTarget.value = questionText
    this.formTarget.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
  }
}
