import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manual-price-check-line-item-form"
export default class extends Controller {
  static targets = [
    "quantityInput",
    "unitPricingInput",
    "annualPriceInput",
    "lowEndPricePerUnitInput",
    "highEndPricePerUnitInput",
    "medianPricePerUnitInput",
    "lowEndTotalAnnualPriceInput",
    "highEndTotalAnnualPriceInput",
    "medianTotalAnnualPriceInput",
  ]
  quantityInputTarget: HTMLInputElement
  unitPricingInputTarget: HTMLInputElement
  annualPriceInputTarget: HTMLInputElement
  lowEndPricePerUnitInputTarget: HTMLInputElement
  highEndPricePerUnitInputTarget: HTMLInputElement
  medianPricePerUnitInputTarget: HTMLInputElement
  lowEndTotalAnnualPriceInputTarget: HTMLInputElement
  highEndTotalAnnualPriceInputTarget: HTMLInputElement
  medianTotalAnnualPriceInputTarget: HTMLInputElement

  calculateToUnitPricing(e) {
    e.preventDefault()
    let annualPrice = parseFloat(this.annualPriceInputTarget.value.replace(/,/g, ""))
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let output = annualPrice / quantity
    this.unitPricingInputTarget.value = output.toString()
    this.unitPricingInputTarget.dispatchEvent(new Event("blur"))
  }

  calculateToAnnualPrice(e) {
    e.preventDefault()
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let unitPrice = parseFloat(this.unitPricingInputTarget.value.replace(/,/g, ""))
    let output = quantity * unitPrice
    this.annualPriceInputTarget.value = output.toString()
    this.annualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  // NOTE Any changes to calculateToAnnualTotal* functions
  // should be mirrored in Ruby in the Contracts::ManualPriceCheckAttributes service concern
  calculateToAnnualTotalLow() {
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let lowEndPricePerUnit = parseFloat(this.lowEndPricePerUnitInputTarget.value.replace(/,/g, ""))
    let output = quantity * lowEndPricePerUnit
    this.lowEndTotalAnnualPriceInputTarget.value = output.toString()
    this.lowEndTotalAnnualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  calculateToAnnualTotalHigh() {
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let highEndPricePerUnit = parseFloat(this.highEndPricePerUnitInputTarget.value.replace(/,/g, ""))
    let output = quantity * highEndPricePerUnit
    this.highEndTotalAnnualPriceInputTarget.value = output.toString()
    this.highEndTotalAnnualPriceInputTarget.dispatchEvent(new Event("blur"))
  }

  calculateToAnnualTotalMedian() {
    let quantity = parseFloat(this.quantityInputTarget.value.replace(/,/g, ""))
    let medianPricePerUnit = parseFloat(this.medianPricePerUnitInputTarget.value.replace(/,/g, ""))
    let output = quantity * medianPricePerUnit
    this.medianTotalAnnualPriceInputTarget.value = output.toString()
    this.medianTotalAnnualPriceInputTarget.dispatchEvent(new Event("blur"))
  }
}
