import { Controller } from "@hotwired/stimulus"
import { setSearchParams } from "../../utils/urls"

// Connects to data-controller="inbox--attachment-viewer"
export default class extends Controller {
  static values = {
    documentId: String,
    invoicesInboundEmailId: String,
  }
  documentIdValue: string
  invoicesInboundEmailIdValue: string

  connect() {
    this.setIdParams()
  }

  disconnect() {
    this.deleteIdParams()
  }

  setIdParams(): void {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set("document_id", this.documentIdValue)
    urlParams.set("invoices_inbound_email_id", this.invoicesInboundEmailIdValue)
    setSearchParams(urlParams)
  }

  deleteIdParams(): void {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete("document_id")
    urlParams.delete("invoices_inbound_email_id")
    setSearchParams(urlParams)
  }
}
