import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="workflows--enhanced-workflow-form"
export default class extends Controller {
  static values = {
    organizationSlug: String,
    workflowsPath: String,
  }

  organizationSlugValue: string
  workflowsPathValue: string

  onFormChange(e): void {
    const organizationSlug = this.organizationSlugValue
    const workflowsPath = this.workflowsPathValue

    const target = e.target
    const form = target.form
    const formData = new FormData(form)

    let params = new URLSearchParams()
    if (target.id === "workflow-request-category-select") {
      params.append("purchase_category_change", "true")
    } else {
      params.append("purchase_category_change", "false")
    }

    // Destroy the tom select instance before the frame re-renders, otherwise the instance is let stale
    // and when the tom select initializes again, it renders opened
    window.dispatchEvent(new CustomEvent("StyledSelect:destroy", { detail: { target: target } }))

    post(`/orgs/${organizationSlug}/settings/${workflowsPath}/new/on_form_change?${params}`, {
      responseKind: "turbo-stream",
      body: formData,
    })
  }
}
