import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="procurement-preference-notes-form"
export default class extends Controller {
  static targets = ["hideWhenNotes", "showWhenNotes"]

  hideWhenNotesTargets: HTMLButtonElement[]
  showWhenNotesTargets: HTMLButtonElement[]

  static values = {
    noteWrapperSelector: {
      type: String,
      default: ".clone-wrapper",
    },
  }
  noteWrapperSelectorValue: string

  connect() {
    this.element.addEventListener("templates:cloneRemoved", this.noteRemoved.bind(this))
    this.adjustNoteButtons()
  }

  disconnect() {
    this.element.removeEventListener("templates:cloneRemoved", this.noteRemoved.bind(this))
  }

  createNote(e: Event) {
    e.preventDefault()

    this.adjustNoteButtons()
  }

  noteRemoved() {
    this.adjustNoteButtons()
  }

  adjustNoteButtons() {
    if (this.renderedNoteCount() > 0) {
      this.hideWhenNotesTargets.forEach((element) => hide(element))
      this.showWhenNotesTargets.forEach((element) => show(element))
    } else {
      this.hideWhenNotesTargets.forEach((element) => show(element))
      this.showWhenNotesTargets.forEach((element) => hide(element))
    }
  }

  renderedNoteCount() {
    const notes = Array.from(this.element.querySelectorAll(this.noteWrapperSelectorValue)).filter(
      (el) => !el.closest("template"),
    )
    return notes.length
  }
}
