import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { hide, show, hideShallowModeModal, showShallowModeModal } from "../../utils"

// Connects to data-controller="workflows--unsaved-task-tab-form-modal"
export default class extends Controller {
  static targets = [
    "modalDiscardChangesButton",
    "modalSwitchTabToInput",
    "modalWrapper",
    "form",
    "tabContent",
    "tabLoader",
  ]
  modalDiscardChangesButtonTarget: HTMLAnchorElement
  modalSwitchTabToInputTarget: HTMLInputElement
  modalWrapperTarget: HTMLDivElement
  formTarget: HTMLFormElement
  tabContentTarget: HTMLFormElement
  tabLoaderTarget: HTMLFormElement

  taskIsUnsaved: boolean = false

  handleTaskFormChangesFunction = this.handleTaskFormChanges.bind(this)

  connect() {
    document.addEventListener("FormChangeDetector:onChange", this.handleTaskFormChangesFunction)
  }

  disconnect() {
    document.removeEventListener("FormChangeDetector:onChange", this.handleTaskFormChangesFunction)
  }

  switchTab(e) {
    if (this.taskIsUnsaved) {
      e.preventDefault()
      if (this.formTarget.checkValidity()) {
        this.showUnsavedChangesModal(e)
      }
    } else {
      this.showLoader()
    }
  }

  handleTaskFormChanges(e: CustomEvent): void {
    const { total } = e.detail
    this.taskIsUnsaved = total > 0
  }

  showUnsavedChangesModal(e): void {
    const redirectUrl = e.currentTarget.href
    const newTab = redirectUrl.includes("/conditions_rule_schema") ? "rules" : "task_details"
    this.modalDiscardChangesButtonTarget.href = redirectUrl
    this.modalSwitchTabToInputTarget.value = newTab
    showShallowModeModal(this.modalWrapperTarget, this.application)
  }

  showLoader() {
    show(this.tabLoaderTarget)
    hide(this.tabContentTarget)
  }

  hideUnsavedChangesModal(e): void {
    e.preventDefault()
    e.stopPropagation()

    this.modalDiscardChangesButtonTarget.href = "#"
    this.modalSwitchTabToInputTarget.value = null

    hideShallowModeModal(this.modalWrapperTarget, this.application)
  }

  discardChanges(e): void {
    const redirectUrl = e.currentTarget.href
    e.preventDefault()
    e.stopPropagation()

    this.hideUnsavedChangesModal(e)
    this.showLoader()
    Turbo.visit(redirectUrl, { frame: "workflow_task_sidebar" })
  }
}
