import { Handle, Position, useNodes, useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { AddFirstTaskButton } from "./addFirstTaskButton"
import React from "react"

const noTasks = (nodes) => nodes.filter((node) => node.type === "workflowTaskCard").length === 0

export const DateTriggerCardNode = ({ data }) => {
  const nodes = useNodes()

  const { cardDimensions, editable, selectedTaskId, redirectToTask } = useWorkflow()
  const { getViewport } = useReactFlow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `285px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")
  const isSelected = selectedTaskId === data.taskId

  const handleOnClick = () => {
    if (!data.editDateTriggerPath || !data.taskId) {
      return
    }

    redirectToTask(data.editDateTriggerPath, JSON.stringify(getViewport()), data.taskId, selectedTaskId)
  }

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className="flex flex-col justify-between pointer-events-auto z-2 p-4 border border-base rounded-md bg-white"
      style={containerStyles}
      onClick={handleOnClick}
    >
      <div>
        <div className="small-heading truncate mb-4">Date Trigger</div>
        <div className="flex items-center">
          <div className="rounded-full bg-red w-4 h-4 flex items-center justify-center">
            <i className="fa-solid fa-caret-right"></i>
          </div>
          <p className="ml-4 body-text body-text--long">{data.dateTriggerDescription}</p>
        </div>
      </div>

      <div>
        <div className="pointer-events-auto z-10 block mt-2 rounded-md text-center space-y-1 bg-purple-500 py-2 px-3">
          <span className="font-semibold text-base text-white">Edit Trigger</span>
        </div>
        <Handle type="source" position={Position.Right} style={handleStyles} />
        {editable && data.addTaskPathRight && noTasks(nodes) && (
          <AddFirstTaskButton actionPath={data.addTaskPathRight} />
        )}
      </div>
    </div>
  )
}
