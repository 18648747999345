import { Controller } from "@hotwired/stimulus"
import { v4 as uuidv4 } from "uuid"
import { hide, show } from "../utils"

// Connects to data-controller="templates"
export default class extends Controller {
  static outlets = ["templates-wrapper"]
  static targets = ["container", "spawn", "template", "deleteButton", "createButton", "maxValueReachedElement"]

  containerTarget: HTMLDivElement
  spawnTarget: HTMLDivElement
  templateTarget: HTMLDivElement
  deleteButtonTargets: HTMLButtonElement[]
  createButtonTarget: HTMLButtonElement
  maxValueReachedElementTargets: HTMLDivElement[]
  hasCreateButtonTarget: boolean
  hasMaxValueReachedElementTarget: boolean

  static values = {
    // The minimum number of clones to generate if there aren't any at connection
    min: {
      type: Number,
      default: 1,
    },
    // The text in the HTML which is replaced with a unique cloneId
    cloneText: {
      type: String,
      default: "NEW_CLONE",
    },
    // The selector for the individual clones
    cloneWrapperSelector: {
      type: String,
      default: ".clone-wrapper",
    },
    // The text in the HTML which is replaced with cloneText
    nestedCloneText: {
      type: String,
      default: "NESTED_CLONE",
    },
  }
  minValue: number
  cloneTextValue: string
  cloneWrapperSelectorValue: string
  nestedCloneTextValue: string

  connect(): void {
    if (this.shouldShowDefaultClones()) {
      this.createDefaultClones()
    }

    this.triggerCountCheck()
  }

  createClone(e: Event) {
    e.preventDefault()

    this.spawnTarget.insertAdjacentHTML("beforebegin", this.buildClone())
    this.triggerCountCheck()
  }

  buildClone() {
    const cloneId = `clone_${uuidv4()}`
    const template = this.templateTarget

    const expression1 = `${this.cloneTextValue}`
    const regExp1 = new RegExp(expression1, "g")

    const expression2 = `${this.nestedCloneTextValue}`
    const regExp2 = new RegExp(expression2, "g")

    return template.innerHTML.replace(regExp1, cloneId).replace(regExp2, this.cloneTextValue)
  }

  createDefaultClones() {
    this.spawnTarget.insertAdjacentHTML("beforebegin", this.buildDefaultClones())
  }

  buildDefaultClones() {
    const clonesQuantity = this.minValue
    const content = []

    for (let i = 1; i <= clonesQuantity; i++) {
      content.push(this.buildClone())
    }

    return content.join("")
  }

  removeClone(e: Event) {
    e.preventDefault()
    const wrapper = e.target.closest(this.cloneWrapperSelectorValue)
    wrapper.remove()

    this.element.dispatchEvent(new CustomEvent("templates:cloneRemoved"))
    this.triggerCountCheck()
  }

  shouldShowDefaultClones() {
    return this.minValue && !this.hasClonesRendered()
  }

  hasClonesRendered() {
    return this.renderedCloneCount() >= this.minValue
  }

  renderedCloneCount() {
    const clones = Array.from(this.containerTarget.querySelectorAll(this.cloneWrapperSelectorValue)).filter(
      (el) => !el.closest("template"),
    )
    return clones.length
  }

  triggerCountCheck() {
    requestAnimationFrame(() => {
      // fires before next repaint

      requestAnimationFrame(() => {
        // fires after next repaint
        if (this.hasTemplatesWrapperOutlet) {
          this.templatesWrapperOutlet.checkClonesCount()
        }

        if (this.renderedCloneCount() > this.minValue) {
          this.deleteButtonTargets.forEach((button) => show(button))
        } else {
          this.deleteButtonTargets.forEach((button) => hide(button))
        }
      })
    })
  }

  handleCountCheckResult(maxMet = false) {
    if (this.hasCreateButtonTarget) {
      if (maxMet) {
        hide(this.createButtonTarget)
        this.showMaxValueReachedElements()
      } else {
        show(this.createButtonTarget)
        this.hideMaxValueReachedElements()
      }
    }
  }

  private hideMaxValueReachedElements() {
    if (!this.hasMaxValueReachedElementTarget) {
      return
    }

    this.maxValueReachedElementTargets.forEach((el) => hide(el))
  }

  private showMaxValueReachedElements() {
    if (!this.hasMaxValueReachedElementTarget) {
      return
    }

    this.maxValueReachedElementTargets.forEach((el) => show(el))
  }
}
