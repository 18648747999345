import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="compact-multi-select"
export default class extends Controller {
  static targets = ["inputWrapper", "pseudoInput", "select", "popper"]
  inputWrapperTarget: HTMLElement
  pseudoInputTarget: HTMLInputElement
  selectTarget: HTMLElement
  popperTarget: HTMLElement

  _popperController: Controller
  originalSelectedValues: string[]

  onSelectedUpdatedFunction: (event) => void
  cancelSelectionFunction = this.cancelSelection.bind(this)
  handleClickOutsideFunction = this.handleClickOutside.bind(this)

  connect() {
    this.onSelectedUpdatedFunction = this.onSelectedUpdated.bind(this)
    window.addEventListener("UndoRedo:selectUpdated", this.onSelectedUpdatedFunction)

    document.addEventListener("close-other-poppers", this.cancelSelectionFunction)
    document.addEventListener("mousedown", this.handleClickOutsideFunction)
  }

  disconnect() {
    window.removeEventListener("UndoRedo:selectUpdated", this.onSelectedUpdatedFunction)
    document.removeEventListener("close-other-poppers", this.cancelSelectionFunction)
    document.removeEventListener("mousedown", this.handleClickOutsideFunction)
  }

  get popperController() {
    if (this._popperController === undefined) {
      this._popperController = this.application.getControllerForElementAndIdentifier(this.inputWrapperTarget, "popper")
    }
    return this._popperController
  }

  handleClickOutside(event) {
    // Check if the click is outside the popper or the inputWrapper
    if (
      this.popperTarget.dataset.show &&
      !this.inputWrapperTarget.contains(event.target) &&
      !this.popperController.element.contains(event.target)
    ) {
      this.cancelSelection(event)
    }
  }

  resumeSelection(ev) {
    ev.stopPropagation()
    ev.preventDefault()

    this.dispatchCompactMultiSelectOpened()

    // Dispatch a custom event to close other poppers
    const closeOtherPoppersEvent = new CustomEvent("close-other-poppers", { bubbles: true })
    window.dispatchEvent(closeOtherPoppersEvent)

    this.popperController.show()
    this.originalSelectedValues = [...this.selectTarget.tomselect.getValue()]

    if (this.pseudoInputTarget.innerText === "Not Set") {
      this.selectTarget.tomselect.open()
    }
  }

  cancelSelection(ev) {
    ev.stopPropagation()
    ev.preventDefault()

    this.dispatchCompactMultiSelectClosed()

    // Reset TomSelect to the original values
    if (this.originalSelectedValues) {
      this.selectTarget.tomselect.setValue(this.originalSelectedValues)
    }
    this.popperController.hide()
  }

  acceptSelection(ev) {
    ev.stopPropagation()
    ev.preventDefault()

    this.dispatchCompactMultiSelectClosed()

    let tsControl = this.inputWrapperTarget.querySelector(".ts-control")
    const selectedItems = tsControl.querySelectorAll('[id^="selected-item_"]')
    const firstSelectedName = this.getName(selectedItems[0])
    this.pseudoInputTarget.innerText = firstSelectedName
      ? this.buildLabel(firstSelectedName, selectedItems.length)
      : "Not Set"
    this.popperController.hide()
  }

  getName(selection) {
    return selection?.childNodes[0]?.textContent.trim() || ""
  }

  buildLabel(firstSelectionName, totalSelections) {
    return totalSelections > 1 ? `${firstSelectionName} +${totalSelections - 1}` : firstSelectionName
  }

  private onSelectedUpdated(event): void {
    const { origin } = event.detail

    if (origin) {
      const tsControl = origin.closest(".table-cell").getElementsByClassName("ts-control")[0]
      const selectedItems = tsControl.querySelectorAll('[id^="selected-item_"]')
      const firstSelectedName = this.getName(selectedItems[0])
      const pseudoInput = origin.closest(".table-cell").getElementsByClassName("pseudo-input")[0]

      if (pseudoInput) {
        pseudoInput.innerText = firstSelectedName ? this.buildLabel(firstSelectedName, selectedItems.length) : "Not Set"
      }
    }
  }

  private dispatchCompactMultiSelectOpened() {
    window.dispatchEvent(
      new CustomEvent("CompactMultiSelect:popperOpened", {
        detail: {
          origin: this.inputWrapperTarget,
        },
      }),
    )
  }

  private dispatchCompactMultiSelectClosed() {
    window.dispatchEvent(
      new CustomEvent("CompactMultiSelect:popperClosed", {
        detail: {
          origin: this.inputWrapperTarget,
        },
      }),
    )
  }
}
