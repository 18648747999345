import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bill-line-item-form"
export default class extends Controller {
  static targets = ["markForDestructionField"]
  markForDestructionFieldTarget: HTMLInputElement

  deleteLineItem = (event: Event) => {
    event.preventDefault()
    this.markForDestructionFieldTarget.value = 1
    this.markForDestructionFieldTarget.dispatchEvent(new Event("change", { bubbles: true }))
  }
}
