import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../utils"

// Connects to data-controller="general-workflow-category"
export default class extends Controller {
  static targets = ["triggerTypeSelect", "startingPointSelect"]
  static values = { renderStartingPointTriggerType: String }

  triggerTypeSelectTarget: HTMLSelectElement
  startingPointSelectTarget: HTMLSelectElement
  hasTriggerTypeSelectTarget: boolean
  renderStartingPointTriggerTypeValue: string

  connect() {
    if (this.hasTriggerTypeSelectTarget) {
      this.onTriggerTypeChange(this.triggerTypeSelectTarget.value)
    }
  }

  onTriggerTypeSelectChange(e) {
    this.onTriggerTypeChange(e.target.value)
  }

  onTriggerTypeChange(newVal) {
    if (newVal === this.renderStartingPointTriggerTypeValue) {
      show(this.startingPointSelectTarget)
    } else {
      hide(this.startingPointSelectTarget)
    }
  }
}
