import { Controller } from "@hotwired/stimulus"
import { disable, enable } from "../utils"
import { show, hide } from "../utils"

// Connects to data-controller="contract-setup-form"
export default class extends Controller {
  static targets = [
    "contractOwnersSelect",
    "departmentSelect",
    "docProvidersSelect",
    "endDateField",
    "setupStageSelect",
    "saveButton",
    "submitToTropicButton",
    "termField",
    "totalContractValueField",
    "paymentTermsSelection",
    "paymentTermsOther",
    "paymentMethodSelection",
    "paymentMethodOther",
  ]
  static values = {
    documentAttached: Boolean,
    freshDocument: Boolean,
    initialDocumentCount: Number,
    initialSetupStageIsPromotionCandidate: Boolean,
    wasDemoted: Boolean,
    hadRequiredFields: Boolean,
    automaticallyPromoteContracts: Boolean,
  }

  contractOwnersSelectTarget: HTMLDivElement
  departmentSelectTarget: HTMLDivElement
  docProvidersSelectTarget: HTMLDivElement
  documentAttachedValue: boolean
  endDateFieldTarget: HTMLInputElement
  freshDocumentValue: boolean
  hasContractOwnersSelectTarget: boolean
  hasDocProvidersSelectTarget: boolean
  hasSaveButtonTarget: boolean
  hasSubmitToTropicButtonTarget: boolean
  hasTermFieldTarget: boolean
  initialDocumentCountValue: Number
  initialSetupStageIsPromotionCandidateValue: boolean
  wasDemotedValue: boolean
  hadRequiredFieldsValue: boolean
  setupStageSelectTarget: HTMLDivElement
  hasSetupStageSelectTarget: boolean
  saveButtonTarget: HTMLButtonElement
  submitToTropicButtonTarget: HTMLButtonElement
  termFieldTarget: HTMLInputElement
  totalContractValueFieldTarget: HTMLInputElement
  paymentTermsSelectionTarget: HTMLSelectElement
  hasPaymentTermsSelectionTarget: boolean
  paymentTermsOtherTarget: HTMLDivElement
  paymentMethodSelectionTarget: HTMLSelectElement
  hasPaymentMethodSelectionTarget: boolean
  paymentMethodOtherTarget: HTMLDivElement

  saveButtonClickHandler = this.saveButtonClick.bind(this)
  submitToTopicButtonClickHandler = this.submitToTopicButtonClick.bind(this)
  onContractOwnerAddedHandler = this.onContractOwnerAdded.bind(this)
  onContractOwnerRemovedHandler = this.onContractOwnerRemoved.bind(this)
  onDocumentRemovedHandler = this.onDocumentRemoved.bind(this)
  onDocumentUploadedHandler = this.onDocumentUploaded.bind(this)
  onTermFieldChangedHandler = this.onTermFieldChanged.bind(this)

  connect() {
    this.documentAttachedValue = false

    this.togglePaymentTermsOther()
    this.togglePaymentMethodOther()

    if (this.hasContractOwnersSelectTarget) {
      window.addEventListener("StyledSelect:itemAdded", this.onContractOwnerAddedHandler)
      window.addEventListener("StyledSelect:itemRemoved", this.onContractOwnerRemovedHandler)
    }
    if (this.hasSaveButtonTarget) {
      window.addEventListener("ContractSetupForm:clickSave", this.saveButtonClickHandler)
    }
    if (this.hasSubmitToTropicButtonTarget) {
      this.disableSubmitToTropicButton()
      window.addEventListener("Docupload:submitEnabled", this.onDocumentUploadedHandler)
      window.addEventListener("Docupload:documentRemoved", this.onDocumentRemovedHandler)
      window.addEventListener("ContractSetupForm:clickSubmit", this.submitToTopicButtonClickHandler)

      this.refreshSubmitForReviewButton()
    }
    if (this.hasTermFieldTarget) {
      window.addEventListener("change", this.onTermFieldChangedHandler)
    }
  }

  disconnect() {
    if (this.hasSaveButtonTarget) {
      window.removeEventListener("ContractSetupForm:clickSave", this.saveButtonClickHandler)
    }
    if (this.hasSubmitToTropicButtonTarget) {
      window.removeEventListener("Docupload:submitEnabled", this.onDocumentUploadedHandler)
      window.removeEventListener("Docupload:documentRemoved", this.onDocumentRemovedHandler)
      window.removeEventListener("ContractSetupForm:clickSubmit", this.submitToTopicButtonClickHandler)
    }
    if (this.hasTermFieldTarget) {
      window.removeEventListener("change", this.onTermFieldChangedHandler)
    }
    if (this.hasContractOwnersSelectTarget) {
      window.removeEventListener("StyledSelect:itemAdded", this.onContractOwnerAddedHandler)
      window.removeEventListener("StyledSelect:itemRemoved", this.onContractOwnerRemovedHandler)
    }
  }

  onContractOwnerAdded(event: CustomEvent) {
    const { origin, item } = event.detail

    if (this.contractOwnersSelectTarget === origin) {
      const option = { ...item, text: item.full_name, value: item.id }
      window.dispatchEvent(
        new CustomEvent("StyledSelect:moveOptionsToOptGroup", {
          detail: { target: this.docProvidersSelectTarget, options: [option], optgroup: "owner" },
        }),
      )
      this.refreshSubmitForReviewButton()
    }
  }

  onContractOwnerRemoved(event: CustomEvent) {
    const { origin, item } = event.detail

    if (this.contractOwnersSelectTarget === origin) {
      const option = { ...item, text: item.full_name, value: item.id }
      window.dispatchEvent(
        new CustomEvent("StyledSelect:moveOptionsToOptGroup", {
          detail: { target: this.docProvidersSelectTarget, options: [option], optgroup: "" },
        }),
      )
      this.refreshSubmitForReviewButton()
    }
  }

  onDocumentUploaded() {
    this.documentAttachedValue = true

    this.refreshSubmitForReviewButton()
  }

  onDocumentRemoved(event: CustomEvent) {
    if (event.detail.file_length === 0) {
      this.documentAttachedValue = false
      this.refreshSubmitForReviewButton()
    }
  }

  onTermFieldChanged() {
    if (this.termFieldTarget.value === "month_to_month") {
      this.endDateFieldTarget.value = ""
      disable(this.endDateFieldTarget)
    } else {
      enable(this.endDateFieldTarget)
    }
    this.refreshSubmitForReviewButton()
  }

  setupStageSelected(event: Event) {
    const stage = event.target?.textContent?.trim()

    if (stage === "In Review") {
      this.disableSubmitToTropicButton()
    } else if (stage === "Action Required") {
      this.freshDocumentValue = false
      this.checkComplete()
    }
  }

  refreshSubmitForReviewButton() {
    if (this.contractSetupStage() === "In Review") {
      this.disableSubmitToTropicButton()
      return
    }
    this.checkComplete()
  }

  checkComplete() {
    if (this.shouldEnableSubmitButton()) {
      this.enableSubmitToTropicButton()
    } else {
      this.disableSubmitToTropicButton()
    }
  }

  shouldEnableSubmitButton() {
    if (!this.initialSetupStageIsPromotionCandidateValue) {
      return false
    } else if (this.wasDemotedValue) {
      return (
        (this.hadRequiredFieldsValue && this.documentAttachedValue) ||
        (!this.hadRequiredFieldsValue && this.requiredFieldsPopulated())
      )
    } else {
      return this.freshDocumentValue || this.documentAttachedValue || this.requiredFieldsPopulated()
    }
  }

  requiredFieldsPopulated() {
    return (
      this.ownerSelected() &&
      this.departmentSelected() &&
      this.termSelected() &&
      this.contractValueEntered() &&
      this.endDateValid() &&
      this.documentExists()
    )
  }

  documentExists() {
    return +this.initialDocumentCountValue > 0 || this.documentAttachedValue
  }

  contractSetupStage() {
    if (!this.hasSetupStageSelectTarget) {
      return ""
    }
    return this.setupStageSelectTarget.innerText.trim()
  }

  ownerSelected() {
    return this.contractOwnersSelectTarget.value !== ""
  }

  departmentSelected() {
    return this.departmentSelectTarget.value !== ""
  }

  contractValueEntered() {
    return +this.totalContractValueFieldTarget.value !== 0
  }

  termSelected() {
    if (!this.hasTermFieldTarget) {
      return false
    }
    return this.termFieldTarget.value !== ""
  }

  endDateValid() {
    return this.termFieldTarget.value === "month_to_month" || this.endDateFieldTarget.value !== ""
  }

  disableSubmitToTropicButton() {
    if (this.hasSubmitToTropicButtonTarget) {
      disable(this.submitToTropicButtonTarget)
    }
    window.dispatchEvent(new CustomEvent("ContractSetupFrom:disableSubmit"))
  }

  enableSubmitToTropicButton() {
    if (this.hasSubmitToTropicButtonTarget) {
      enable(this.submitToTropicButtonTarget)
    }
    window.dispatchEvent(new CustomEvent("ContractSetupFrom:enableSubmit"))
  }

  togglePaymentTermsOther() {
    if (this.hasPaymentTermsSelectionTarget) {
      if (this.paymentTermsSelectionTarget.value === "other") {
        show(this.paymentTermsOtherTarget)
      } else {
        hide(this.paymentTermsOtherTarget)
      }
    }
  }

  togglePaymentMethodOther() {
    if (this.hasPaymentMethodSelectionTarget) {
      if (this.paymentMethodSelectionTarget.value === "other") {
        show(this.paymentMethodOtherTarget)
      } else {
        hide(this.paymentMethodOtherTarget)
      }
    }
  }

  submitToTopicButtonClick() {
    if (this.hasSubmitToTropicButtonTarget) {
      this.submitToTropicButtonTarget.click()
    }
  }

  saveButtonClick() {
    if (this.hasSubmitToTropicButtonTarget) {
      this.saveButtonTarget.click()
    }
  }
}
