import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"
import { debounce } from "lodash"
import { ChangeEvent } from "react"
import * as Sentry from "@sentry/browser"

export default class extends Controller {
  formTarget: HTMLFormElement
  filterFieldsContainerTargets: HTMLElement[]
  filterOperatorFieldTarget: HTMLInputElement
  groupFieldTargets: HTMLInputElement[]
  clearGroupFieldTarget: HTMLInputElement
  sortByFieldTarget: HTMLInputElement
  sortDirFieldTarget: HTMLInputElement
  scopeFieldTarget: HTMLInputElement
  searchFieldTarget: HTMLInputElement
  loaderTarget: HTMLInputElement
  scrollableTarget: HTMLElement
  columnsFieldTarget: HTMLElement
  selectAllToggleTarget: HTMLInputElement
  localSearchOnlyItemTargets: HTMLElement[]
  perPageDropdownTarget: HTMLSelectElement

  hasPerPageDropdownTarget: boolean

  sortByValue: string
  sortDirValue: string
  scopeValue: string
  toggableScopeValue: string
  horizontalScrollValue: number
  columnsUrlValue: string
  currentOrganizationIdValue: string
  pathUrlValue: string
  checkColumnToggleStatusFunc: () => void
  outerFormIdValue: string
  outerFormMethodValue: string
  customFilterKeyValue: string
  customFilterValue: string

  static targets = [
    "form",
    "filterFieldsContainer",
    "filterOperatorField",
    "groupField",
    "clearGroupField",
    "searchField",
    "scopeField",
    "sortByField",
    "sortDirField",
    "loader",
    "scrollable",
    "columnsField",
    "localSearchOnlyItem",
    "perPageDropdown",
    "selectAllToggle",
  ]

  static values = {
    filterIndex: Number,
    scope: String,
    query: String,
    sortBy: String,
    sortDir: String,
    toggableScope: String,
    horizontalScroll: Number,
    columnsUrl: String,
    currentOrganizationId: String,
    pathUrl: String,
    // Forces the table to refresh the results from the server
    // when clicking the browser navigation buttons
    forceRefresh: {
      type: Boolean,
      default: false,
    },
    retainFiltersOnScope: {
      type: Boolean,
      default: true,
    },
    // By default, the table overrides the current URL query params by the filters ones
    // this allows to forward the existing params, so they don't get lost
    forwardOriginalParams: {
      type: Boolean,
      default: false,
    },
    localSearchOnly: {
      type: Boolean,
      default: false,
    },
    outerFormId: String,
    outerFormMethod: String,

    customFilterKey: String,
    customFilter: String,
    noUrlRewrites: {
      type: Boolean,
      default: false,
    },
  }

  forceRefreshValue: boolean
  retainFiltersOnScopeValue: boolean
  forwardOriginalParamsValue: boolean
  localSearchOnlyValue: boolean

  toggleColumnsInRowFunc = this.toggleColumnsInRow.bind(this)
  toggleColumnsInRowFromEventFunc = this.toggleColumnsInRowFromEvent.bind(this)

  noUrlRewritesValue: boolean

  initialize() {
    this.onSearchKeyUp = debounce(this.onSearchKeyUp, 500)
    this.hideOpenTableDropdownElements = debounce(this.hideOpenTableDropdownElements, 500, { leading: true })
  }

  connect() {
    this.preserveExistingQueryParams()
    if (this.hasSortByFieldTarget && this.hasSortDirFieldTarget) {
      this.sortByFieldTarget.value = this.sortByValue
      this.sortDirFieldTarget.value = this.sortDirValue
    }
    this.copyDefaultFiltersToUrl()
    this.setInitialFilterOperators()

    if (this.hasColumnsFieldTarget) {
      this.checkColumnToggleStatusFunc = this.checkColumnToggleStatus.bind(this)
      this.toggleableColumns.forEach((toggle) => toggle.addEventListener("change", this.checkColumnToggleStatusFunc))
      this.toggleColumns()
      this.checkColumnToggleStatus()
      this.toggleColumns = debounce(this.toggleColumns.bind(this), 300)
      this.formTarget.addEventListener("turbo:submit-end", this.toggleColumns)
      window.addEventListener("bulkEdit:rowRerendered", this.toggleColumnsInRowFromEventFunc)
    }

    this.conditionallyHideLoader()

    if (this.forceRefreshValue) {
      window.addEventListener("popstate", this.refreshOnBackAndForwardButtons)
    }
  }

  disconnect() {
    if (this.forceRefreshValue) {
      window.removeEventListener("popstate", this.refreshOnBackAndForwardButtons)
    }

    if (this.hasColumnsFieldTarget) {
      this.toggleableColumns.forEach((toggle) => toggle.removeEventListener("change", this.checkColumnToggleStatusFunc))
      this.formTarget.removeEventListener("turbo:submit-end", this.toggleColumns)
      window.removeEventListener("bulkEdit:rowRerendered", this.toggleColumnsInRowFromEventFunc)
    }
  }

  toggleColumnsInRowFromEvent(event) {
    this.toggleColumnsInRowFunc(event.detail.row)
  }

  preserveExistingQueryParams() {
    // https://www.w3.org/TR/2011/WD-html5-20110525/association-of-controls-and-forms.html#form-submission-algorithm
    // By design, when submitting a form (GET) the destination is a new URL that is equal to the "action"
    // except that its <query> component. So if the passed "path" parameter includes query string params
    // they are ignored unless we preserve them:
    if (this.pathUrlValue.includes("?")) {
      const params = new URLSearchParams(this.pathUrlValue.split("?")[1])
      params.forEach((value, key) => {
        // Creates a hidden input per each query string
        const input = document.createElement("input")
        input.setAttribute("type", "hidden")
        input.setAttribute("name", key)
        input.setAttribute("value", value)
        this.formTarget.appendChild(input)
      })
    }
  }

  copyDefaultFiltersToUrl() {
    const filterParams = new URLSearchParams()
    this.copyInputFilters(filterParams)
    this.copySelectFilters(filterParams)
    this.copyPageFilters(filterParams)
    this.copyCustomFilters(filterParams)

    let params = filterParams
    if (this.forwardOriginalParamsValue) {
      const originalParams = this.removeTableFilterParams(new URLSearchParams(window.location.search))
      originalParams.forEach((value, key) => {
        params.append(key, value)
      })
    }

    if (params.toString() !== "") {
      this.replaceUrl(`${window.location.pathname}?${params.toString()}`)
    }
  }

  copyInputFilters(params) {
    this.filterFieldsContainerTargets.forEach((filterFieldsContainerTarget) => {
      const inputs = Array.from(filterFieldsContainerTarget.getElementsByTagName("input"))
      const includeHiddenFields = filterFieldsContainerTarget.dataset.includeHiddenFields === "true"
      inputs.forEach((input) => {
        if (input.disabled || ["submit", "button"].includes(input.type)) {
          // If an input is disabled, the form will not submit the data for it so we should ignore it here as well.
          // There's also a few types we just want to ignore when copying.
          return
        } else if (!["text", "hidden", "date", "checkbox", "radio"].includes(input.type)) {
          // if an input is in an unhandled type, we should fire off a sentry warning for future developers to see
          Sentry.captureException(new Error(`Unrecognized input type: ${input.type}`))
          return
        } else if (input.type === "hidden" && !includeHiddenFields) {
          // if hidden fields are not requested, we should ignore them
          return
        } else if ((["checkbox", "radio"].includes(input.type) && !input.checked) || !input.value) {
          // if no value is set (or checked for checkboxes and radios), we should ignore it
          return
        }
        params.append(input.name, input.value)
      })
    })
  }

  copySelectFilters(params) {
    this.filterFieldsContainerTargets.forEach((filterFieldsContainerTarget) => {
      const selects = Array.from(filterFieldsContainerTarget.getElementsByTagName("select"))
      selects.forEach((select) => {
        if (select.disabled) {
          // If a select is disabled, the form will not submit the data for it
          // so we should ignore it here as well.
          return
        } else if (select.name.includes("operator")) {
          const baseName = select.name.replace("[operator]", "").replace("filter_by[", "").slice(0, -1)
          if (params.toString().includes(baseName)) {
            params.append(select.name, select.value)
          } else {
            return
          }
        } else if (select.multiple && select.value) {
          Array.from(select.selectedOptions).forEach((option) => {
            params.append(select.name, option.value)
          })
        } else if (select.value) {
          params.append(select.name, select.value)
        }
      })
    })
  }

  copyPageFilters(params) {
    if (this.hasPerPageDropdownTarget) {
      params.append("per_page", this.perPageDropdownTarget.value)
    }
  }

  copyCustomFilters(params) {
    if (this.customFilterKeyValue) {
      params.append(this.customFilterKeyValue, this.customFilterValue)
    }
  }

  scrollableTargetConnected(target: HTMLElement) {
    target.scrollLeft = this.horizontalScrollValue
    target.addEventListener("scroll", (event) => this.syncHorizontalScroll(event))
    target.addEventListener("scroll", this.hideOpenTableDropdownElements)
  }

  scrollableTargetDisconnected(target: HTMLElement) {
    target.removeEventListener("scroll", (event) => this.syncHorizontalScroll(event))
    target.removeEventListener("scroll", this.hideOpenTableDropdownElements)
  }

  syncHorizontalScroll(event: { target: EventTarget }): void {
    this.horizontalScrollValue = (<HTMLElement>event.target).scrollLeft
  }

  hideOpenTableDropdownElements(): void {
    document.body.click()
  }

  addFilter(): void {
    this.submit()
  }

  setInitialFilterOperators(): void {
    this.filterOperatorFieldTargets.forEach((input) => {
      this.toggleMaxValueInput(input)
    })
  }

  changeFilterOperator(event: { target: HTMLElement; value: String }): void {
    this.toggleMaxValueInput(event.target)
  }

  toggleMaxValueInput(operatorInput: HTMLInputElement): void {
    const inputIndex = operatorInput.dataset["index"]
    const maxValueElements = Array.from(document.getElementsByClassName(`filter-by-max-${inputIndex}`))
    const hidden = operatorInput.value === "Between" ? false : true
    maxValueElements.forEach((element) => {
      element.hidden = hidden
    })
  }

  changeScope(event: { preventDefault: () => void; params: { scope: string } }): void {
    event.preventDefault()

    const isSelect = event.target.type === "select-one"
    const newScope = isSelect ? event.target.value : event.params.scope

    const toggableScope = this.toggableScopeValue
    const hasToggableScope = this.scopeFieldTarget.value.includes(toggableScope)

    const shouldSetTogglableScope = toggableScope && newScope === toggableScope && !hasToggableScope
    const shouldRemoveToggableScope = toggableScope && newScope === ""
    const shouldReplaceNonToggableScope = toggableScope && newScope !== toggableScope && hasToggableScope

    if (shouldSetTogglableScope) {
      const multipleScopes = this.formatMultipleScopes([this.scopeValue, newScope])

      this.scopeValue = multipleScopes
      this.scopeFieldTarget.value = multipleScopes
    } else if (shouldRemoveToggableScope) {
      const removedToggableScope = this.scopeFieldTarget.value.replace(`,${toggableScope}`, "")

      this.scopeValue = removedToggableScope
      this.scopeFieldTarget.value = removedToggableScope
    } else if (shouldReplaceNonToggableScope) {
      const multipleScopes = this.formatMultipleScopes([newScope, toggableScope])

      this.scopeValue = multipleScopes
      this.scopeFieldTarget.value = multipleScopes
    } else {
      this.scopeValue = newScope
      this.scopeFieldTarget.value = newScope
    }

    // Disables the sort and filters fields to prevent them to be sent by the form
    if (!this.retainFiltersOnScopeValue) {
      const sortByField = this.element.querySelector('[name="sort_by"]') as HTMLInputElement
      const sortDirField = this.element.querySelector('[name="sort_dir"]') as HTMLInputElement
      const queryField = this.element.querySelector('[name="q"]') as HTMLInputElement

      if (sortDirField) sortByField.disabled = true
      if (sortDirField) sortDirField.disabled = true
      if (queryField) queryField.disabled = true

      const groupByFields = this.element.querySelectorAll('[name="group_by"]')
      groupByFields.forEach((field: HTMLInputElement) => {
        field.disabled = true
      })

      const filterByFields = this.element.querySelectorAll('[name^="filter_by["][name$="]"]')
      filterByFields.forEach((field: HTMLInputElement | HTMLSelectElement) => {
        field.disabled = true
      })
    }

    this.submit()
  }

  replaceScope(event: { preventDefault: () => void; params: { scope: string } }): void {
    event.preventDefault()
    const newScope = event.params.scope
    this.scopeValue = newScope
    this.scopeFieldTarget.value = newScope
    this.submit()
  }

  formatMultipleScopes(scopes: string[]): string {
    return scopes.join(",")
  }

  clearSearch(): void {
    // 1. clear search input
    this.searchFieldTarget.value = ""

    if (this.localSearchOnlyValue) {
      this.searchLocally()
    } else {
      // 2. set q to empty string
      const params = new URLSearchParams(window.location.search)

      params.set("q", "")

      // 3. update url
      this.replaceUrl(`${window.location.pathname}?${params.toString()}`)

      // 4. reload table
      this.submit()
    }
  }

  clearFilters(event): void {
    const filterFieldsContainerTarget = event.target.closest('[data-table-target="filterFieldsContainer"]')
    this.resetFilterByFields(filterFieldsContainerTarget)
    this.resetFilterByQueryParams()
    this.submit()
  }

  clearAll(): void {
    this.filterFieldsContainerTargets.forEach((filterFieldsContainerTarget) => {
      this.resetFilterByFields(filterFieldsContainerTarget, true)
      this.resetFilterByQueryParams()
    })
    this.submit()
  }

  removeFilter(event): void {
    const removeFilterButton = event.target.closest(".remove-filter")
    const filterKey = removeFilterButton.getAttribute("data-filter-key")
    const filterContainerId = removeFilterButton.getAttribute("data-filter-container-id")
    let inputs = []
    let selects = []

    if (filterContainerId) {
      const filterContainer = document.getElementById(filterContainerId)
      inputs = Array.from(filterContainer.querySelectorAll("input"))
      selects = Array.from(filterContainer.querySelectorAll("select"))
    } else if (filterKey) {
      inputs = Array.from(this.formTarget.querySelectorAll(`input[name*="${filterKey}"]`))
      selects = Array.from(this.formTarget.querySelectorAll(`select[name*="${filterKey}"]`))
    }

    inputs.forEach((input) => {
      switch (input.type) {
        case "date":
        case "text":
        case "hidden":
          input.value = ""
          break
        case "checkbox":
        case "radio":
          input.checked = false
          break
      }
    })

    selects.forEach((select) => {
      if (!select.disabled) {
        select.tomselect && select.tomselect.clear()
        select.value = ""
      }
    })

    this.submit()
  }

  resetFilterByFields(filterFieldsContainerTarget, clearHiddenFields = false): void {
    if (!filterFieldsContainerTarget) {
      return
    }

    const inputs = Array.from(filterFieldsContainerTarget.getElementsByTagName("input"))
    inputs.forEach((input) => {
      switch (input.type) {
        case "date":
        case "text":
          input.value = ""
          break
        case "hidden":
          if (clearHiddenFields) {
            input.value = ""
          }
          break
        case "checkbox":
        case "radio":
          input.checked = false
          break
      }
    })

    const selects = Array.from(filterFieldsContainerTarget.getElementsByTagName("select"))
    selects.forEach((select) => {
      // Disabled selects won't be submitted and getting rid of their value can
      // cause misbehaviors for default selected values (as in the MultiCurrencyInputComponent).
      if (select.disabled) {
        return
      }
      select.tomselect && select.tomselect.clear()
      select.value = ""
    })
  }

  resetFilterByQueryParams(): void {
    const params = new URLSearchParams(window.location.search)
    for (let key of params.keys()) {
      key.match(/filter_by/) && params.delete(key)
    }
    this.replaceUrl(`${window.location.pathname}?${params.toString()}`)
  }

  clearGroup(): void {
    // 1. clear a checked group_by radio btn
    const checkedRadioButton = this.groupFieldTargets.find((groupRadioButton) => groupRadioButton.checked)
    if (checkedRadioButton) {
      checkedRadioButton.checked = false
    }
    this.clearGroupFieldTarget.checked = true

    const params = new URLSearchParams(window.location.search)

    // 2. set group_by to empty string
    params.set("group_by", "")

    // 3. update url
    this.replaceUrl(`${window.location.pathname}?${params.toString()}`)

    // 4. reload table
    this.submit()
  }

  oppositeDir(): string {
    return this.sortDirValue && this.sortDirValue === "asc" ? "desc" : "asc"
  }

  changeSort(event: { preventDefault: () => void; params: { sortBy: string } }): void {
    event.preventDefault()
    const { sortBy } = event.params
    const sortDir = sortBy === this.sortByValue ? this.oppositeDir() : "asc"
    this.sortByValue = sortBy
    this.sortDirValue = sortDir

    this.sortByFieldTarget.value = sortBy
    this.sortDirFieldTarget.value = sortDir
    this.submit()
  }

  replaceUrl(newUrl: string) {
    if (this.noUrlRewritesValue) {
      return
    }
    history.replaceState(history.state, "", newUrl)
  }

  showLoader(e): void {
    if (this.loaderTargets.length > 1) {
      this.selectLoadersToShow(e)
    } else if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove("hidden")
    }
  }

  selectLoadersToShow(e) {
    if (e) {
      const eventSource = e.srcElement
      const tableList = document.querySelectorAll('[data-controller^="table-component"]')

      tableList.forEach((table, index) => {
        if (table.contains(eventSource)) {
          this.loaderTargets[index].classList.remove("hidden")
        }
      })
    } else {
      this.loaderTargets.forEach((loader) => {
        loader.classList.remove("hidden")
      })
    }
  }

  conditionallyHideLoader(): void {
    const shouldHideLoader = this.hasLoaderTarget && !this.loaderTarget.classList.contains("hidden")

    if (shouldHideLoader) {
      this.loaderTarget.classList.add("hidden")
    }
  }

  onSearchKeyUp(e): void {
    if (this.localSearchOnlyValue) {
      this.searchLocally()
    } else if (e.key !== "Enter") {
      this.submit()
    }
  }

  searchLocally(): void {
    let lowerCaseFilterTerm = this.searchFieldTarget.value.toLowerCase()

    this.localSearchOnlyItemTargets.forEach((el, i) => {
      let filterableKey = el.getAttribute("data-filter-key")

      el.classList.toggle("hidden", !filterableKey.includes(lowerCaseFilterTerm))
    })
  }

  saveColumns(): void {
    const toggles = document.querySelectorAll(".toggle-option")
    const columns = {}
    Array.from(toggles).forEach((toggle) => {
      columns[toggle.name] = toggle.checked
    })
    columns["session_path"] = this.pathUrlValue

    post(this.columnsUrlValue, {
      body: {
        columns,
        current_organization_id: this.currentOrganizationIdValue,
      },
    })

    this.toggleColumns()
  }

  toggleColumns(): void {
    this.allColumnToggles.forEach((toggle) => {
      const toggleName = toggle.id.replace("toggle_", "")
      const columnHeader = this.element.querySelector(`.table-header-group .${toggleName}`)
      if (!columnHeader) {
        return
      }

      const columnIndex = Array.from(columnHeader.parentElement.children).indexOf(columnHeader)

      this.cellsInColumn(columnIndex).forEach((cell: HTMLElement) => {
        cell.style.display = toggle.checked ? "table-cell" : "none"
      })
    })
  }

  toggleColumnsInRow(row): void {
    this.allColumnToggles.forEach((toggle) => {
      const toggleName = toggle.id.replace("toggle_", "")
      const columnHeader = this.element.querySelector(`.table-header-group .${toggleName}`)
      if (!columnHeader) {
        return
      }

      const columnIndex = Array.from(columnHeader.parentElement.children).indexOf(columnHeader)

      const cell = this.cellInColumnAndRow(columnIndex, row)
      cell.style.display = toggle.checked ? "table-cell" : "none"
    })
  }

  eventToggleColumnsInRow(event) {
    this.toggleColumnsInRowFunc(event.detail.row)
  }

  cellsInColumn(columnIndex: number): NodeListOf<HTMLElement> {
    return this.element.querySelectorAll(`.table-cell:nth-of-type(${columnIndex + 1})`)
  }

  cellInColumnAndRow(columnIndex: number, row: HTMLElement): HTMLElement {
    return row.querySelector(`.table-cell:nth-of-type(${columnIndex + 1})`)
  }

  toggleAllColumns(): void {
    const { allUnchecked, someChecked } = this.checkedColumnStatus

    const shouldCheckToggle = allUnchecked || someChecked

    this.toggleableColumns.forEach((toggle) => {
      toggle.checked = shouldCheckToggle
    })

    this.checkColumnToggleStatus()
  }

  checkColumnToggleStatus(): void {
    const { allChecked, someChecked } = this.checkedColumnStatus

    if (someChecked) {
      this.selectAllToggleTarget.indeterminate = true
    } else {
      this.selectAllToggleTarget.indeterminate = false
      this.selectAllToggleTarget.checked = allChecked
    }
  }

  get checkedColumnStatus() {
    const toggles = this.toggleableColumns
    const checkedCount = toggles.filter((toggle) => toggle.checked).length
    const allChecked = checkedCount === toggles.length
    const allUnchecked = checkedCount === 0
    const someChecked = !allChecked && !allUnchecked

    return {
      allChecked,
      allUnchecked,
      someChecked,
    }
  }

  get allColumnToggles(): HTMLInputElement[] {
    const toggles: HTMLInputElement[] = Array.from(document.querySelectorAll(".toggle-option"))
    return toggles.filter((toggle) => toggle !== this.selectAllToggleTarget)
  }

  get toggleableColumns(): HTMLInputElement[] {
    return this.allColumnToggles.filter((toggle) => !toggle.disabled)
  }

  submit(): void {
    this.appendRerenderAttributes()
    try {
      this.showLoader()
    } catch (e) {
      // do nothing
    } finally {
      if (this.outerFormIdValue) {
        this.submitOuterForm()
      } else {
        this.formTarget.requestSubmit()
      }
    }
  }

  submitOuterForm(): void {
    let outerForm = document.getElementById(this.outerFormIdValue)
    outerForm.method = this.outerFormMethodValue
    outerForm.requestSubmit()
  }

  refreshOnBackAndForwardButtons() {
    Turbo.visit(window.location.href, { action: "replace" })
  }

  setPageFilters() {
    if (this.hasPerPageDropdownTarget) {
      let input = document.querySelector("input[type='hidden'][name='per_page']")
      if (!input) {
        input = document.createElement("input")
      }
      input.setAttribute("type", "hidden")
      input.setAttribute("name", "per_page")
      input.setAttribute("value", this.perPageDropdownTarget.value)
      this.formTarget.appendChild(input)
    }
  }

  onPerPageChange() {
    this.setPageFilters()
    this.submit()
  }

  private removeTableFilterParams(params: URLSearchParams) {
    const tableFilters = [
      "filter_by",
      "sort_by",
      "sort_dir",
      "group_by",
      "skip_filtering",
      "skip_sorting",
      "skip_scoping",
      "custom_sort",
      "secondary_sort",
      "scopes_arguments",
      "filter_context",
      "distinct",
      "q",
    ]
    const keysToDelete = []

    for (const key of params.keys()) {
      const matches = tableFilters.some((filter) => key === filter || key.match(new RegExp(`${filter}\\[.*\\]`)))
      if (matches) {
        keysToDelete.push(key)
      }
    }

    keysToDelete.forEach((keyToDelete) => {
      params.delete(keyToDelete)
    })
    return params
  }

  private appendRerenderAttributes() {
    this.element.setAttribute("data-table-pending", "true")
    if (this.element.id) {
      const id = (this.element.id as any).replaceAll(" ", "-")
      this.element.setAttribute(`data-table-${id}-pending`, "true")
    }
  }
}
