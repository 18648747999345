import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parentCheckbox", "checkbox", "percentage", "amount"]

  parentCheckboxTarget: HTMLInputElement
  checkboxTargets: HTMLInputElement[]

  onParentCheckboxChange(event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked
    this.checkboxTargets.forEach((checkbox) => (checkbox.checked = isChecked))
  }

  onCheckboxChange() {
    this.parentCheckboxTarget.checked = this.checkboxTargets.some((checkbox) => checkbox.checked)
  }
}
