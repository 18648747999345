import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import { debounce } from "lodash"

// Connects to data-controller="rerender-contract-in-bulk-edit"
export default class extends Controller {
  static values = {
    submissionUrl: String,
    hasBeenRerendered: { Boolean, default: false },
    isUpdating: { Boolean, default: false },
  }
  static targets = ["fieldTriggersRerender", "row"]

  submissionUrlValue: String
  hasBeenRerenderedValue: Boolean
  isUpdatingValue: Boolean
  fieldTriggersRerenderTargets: HTMLElement[]
  rowTarget: HTMLElement
  handleFieldUpdateFunction: (event) => void
  debouncedUpdateContract: (event) => void

  connect() {
    if (this.hasBeenRerenderedValue) {
      window.dispatchEvent(new CustomEvent("bulkEdit:rowRerendered", { detail: { row: this.rowTarget } }))
    }
    this.debouncedUpdateContract = debounce(this.updateContract.bind(this), 800)
    this.handleFieldUpdateFunction = this.handleFieldUpdate.bind(this)
    window.addEventListener("UndoRedo:inputUpdated", this.handleFieldUpdateFunction)
    window.addEventListener("UndoRedo:selectUpdated", this.handleFieldUpdateFunction)
  }

  disconnect() {
    window.removeEventListener("UndoRedo:inputUpdated", this.handleFieldUpdateFunction)
    window.removeEventListener("UndoRedo:selectUpdated", this.handleFieldUpdateFunction)
  }

  handleFieldUpdate(event) {
    if (this.fieldTriggersRerenderTargets.includes(event.detail.origin)) {
      const contractRow = event.detail.origin.closest(".table-row")
      this.updateContractForRow(contractRow)
    }
  }

  updateContract(event) {
    if (this.isUpdatingValue) return
    this.isUpdatingValue = true
    const input = event.target
    const contractRow = input.closest(".table-row")
    this.updateContractForRow(contractRow)
  }

  updateContractForRow(contractRow) {
    const formData = new FormData()

    // Extract only the relevant inputs for the specific contract
    contractRow.querySelectorAll("input, select").forEach((field) => {
      if (field.name && !field.disabled) {
        const originalName = field.name
        // remove the [UUID] from the field name, results in contract[field_name]
        const modifiedName = originalName.replace(
          /\[([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})\]/,
          "",
        )

        if (field.multiple) {
          Array.from(field.selectedOptions).forEach((option) => {
            formData.append(modifiedName, option.value)
          })
        } else {
          formData.append(modifiedName, field.value)
        }
      }
    })

    patch(this.submissionUrlValue, {
      responseKind: "turbo-stream",
      body: formData,
    })

    window.dispatchEvent(new CustomEvent("bulkEdit:rowRerendering"))
    setTimeout(() => {
      this.isUpdatingValue = false
    }, 900)
  }
}
