import { Controller } from "@hotwired/stimulus"
import { hide, show } from "../../utils/index"

const DYNAMIC_PANELS_CSS_SELECTOR = ".canopy_panel_accordion_group_dynamical_panel"

/* Connects to data-controller="canopy--panel-accordion-group-component"
 */
export default class extends Controller {
  static targets = ["panelGroup"]

  panelGroupTarget: HTMLElement
  panelTriggersMap: Map<HTMLElement, string> = new Map()
  visibleDynamicPanels: Set<HTMLDivElement> = new Set()

  findPanel = (panelId: string): HTMLElement => {
    return this.panelGroupTarget.querySelector(`[id="${panelId}"]`)
  }

  hidePanelForTrigger = (trigger: HTMLElement) => {
    const panelId = this.panelTriggersMap.get(trigger)
    this.hidePanelWithId(panelId)
  }

  hidePanelOnClose = (e: MouseEvent) => {
    const button = e.currentTarget as HTMLButtonElement
    const panel = button.closest(DYNAMIC_PANELS_CSS_SELECTOR) as HTMLDivElement

    hide(panel)
    window.dispatchEvent(new CustomEvent("AccordionPanel:closed"))
    this.setOrUnsetAsCurrentAccordionPanel(panel)
    this.visibleDynamicPanels.delete(panel)
  }

  hidePanelWithId = (panelId: string) => {
    const panel = this.findPanel(panelId)

    hide(panel)
    this.setOrUnsetAsCurrentAccordionPanel(panel)
    this.visibleDynamicPanels.delete(panel as HTMLDivElement)
  }

  setOrUnsetAsCurrentAccordionPanel = (panel: HTMLElement) => {
    ;(panel.querySelector("details > summary") as HTMLElement).click()
  }

  showPanelOnOpen = (e: Event) => {
    const trigger = e.currentTarget as HTMLElement
    const panelId = e.params.panelId
    const panel = this.findPanel(panelId)

    this.panelTriggersMap.set(trigger, panelId)

    if (!this.visibleDynamicPanels.has(panel as HTMLDivElement)) {
      show(panel)
      this.setOrUnsetAsCurrentAccordionPanel(panel)
      this.visibleDynamicPanels.add(panel as HTMLDivElement)
    }
  }
}
