import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="negotiation-insights--slider"
export default class extends Controller {
  static targets = ["slider", "sliderVisual", "select", "label", "remainingBar"]

  sliderTarget: HTMLInputElement
  sliderVisualTarget: HTMLElement
  selectTarget: HTMLSelectElement
  labelTarget: HTMLElement
  remainingBarTarget: HTMLElement

  static values = {
    position: { type: Number, default: 60 },
    identifier: String,
  }

  positionValue: number
  identifierValue: string

  connect(): void {
    this.updateFromValue(this.positionValue)
  }

  sliderChanged(event: Event): void {
    const input = event.target as HTMLInputElement
    this.updateFromValue(parseInt(input.value))
  }

  selectChanged(): void {
    const percentage = parseInt(
      this.selectTarget.options[this.selectTarget.selectedIndex].getAttribute("data-position-value"),
    )
    this.updateFromValue(percentage)
  }

  private updateFromValue(percentage: number): void {
    this.positionValue = percentage

    // Update range input
    this.sliderTarget.value = percentage.toString()

    // Update visual elements
    if (percentage === 0) {
      this.sliderVisualTarget.style.left = "0"
      this.remainingBarTarget.style.width = "100%"
    } else {
      this.sliderVisualTarget.style.left = `calc(${percentage}% - 10px)`
      this.remainingBarTarget.style.width = `${100 - percentage}%`
    }

    // Update select and label
    const selectedOption = Array.from(this.selectTarget.options).find(
      (option) => parseInt(option.getAttribute("data-position-value") || "0") === percentage,
    )

    this.selectTarget.value = selectedOption.value
    const selectedText = selectedOption.text
    this.labelTarget.textContent = selectedText

    this.labelTarget.textContent = selectedText

    // Update ARIA attributes
    this.sliderTarget.setAttribute("aria-valuenow", percentage.toString())
    this.sliderTarget.setAttribute("aria-valuetext", selectedText)

    // Dispatch change event
    this.dispatch("changed", {
      detail: {
        value: percentage,
        label: selectedText,
        identifier: this.identifierValue,
      },
    })
  }
}
