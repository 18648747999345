import { Controller } from "@hotwired/stimulus"
import { parse } from "iso8601-duration"

// Connects to data-controller="request-form--duration"
export default class extends Controller {
  static targets = ["numberField", "durationField"]

  numberFieldTargets: HTMLInputElement[]
  durationFieldTarget: HTMLInputElement

  hasNumberFieldTarget: boolean
  hasDurationFieldTarget: boolean

  connect() {
    this.setNumberInputFieldValues()
  }

  disconnect() {}

  onNumberInputChange() {
    this.updateDurationField()
  }

  private updateDurationField() {
    const isoDuration = this.toDurationISOFormat()
    this.durationFieldTarget.value = isoDuration === "P0D" ? "" : isoDuration
  }

  private getNumberInputFieldValues() {
    const values = {}
    if (!this.hasNumberFieldTarget) {
      return values
    }

    this.numberFieldTargets.forEach((input) => {
      values[input.dataset.unit] = parseInt(input.value || "0")
    })
    return values
  }

  private setNumberInputFieldValues() {
    if (!this.hasNumberFieldTarget) {
      return
    }
    const values = this.fromDurationISOFormat()
    const nonZeroValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != 0))
    this.numberFieldTargets.forEach((input) => {
      input.value = nonZeroValues[input.dataset.unit]
    })
  }

  private fromDurationISOFormat() {
    if (!this.hasDurationFieldTarget || !this.durationFieldTarget.value) {
      return {}
    }
    return parse(this.durationFieldTarget.value)
  }

  // We need the weeks unit to be combined with the Years, Months and Days which is only supported by the ISO 8601-2
  // standard. The npm package we are using to parse the duration is the only one at the moment to support that, but unfortunately
  // it does not come with a method to convert a JS object to a ISO duration string: https://www.npmjs.com/package/iso8601-duration
  // This is a basic converter that will serve our purpose (it does not account for time), ideally we would replace this if
  // the library ever adds a method to do so, or if we find another library.
  private toDurationISOFormat() {
    const values = this.getNumberInputFieldValues()
    const nonZeroValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != 0))
    if (!Object.keys(nonZeroValues).length) {
      return ""
    }
    let string = "P"
    Object.keys(nonZeroValues).forEach((k) => {
      string += nonZeroValues[k]
      string += this.getISOCharacter(k)
    })
    return string
  }

  private getISOCharacter(unit: string) {
    if (unit === "years") {
      return "Y"
    } else if (unit === "months") {
      return "M"
    } else if (unit === "weeks") {
      return "W"
    } else if (unit === "days") {
      return "D"
    }
  }
}
