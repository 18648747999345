import { Controller } from "@hotwired/stimulus"
import { disable, enable, setSessionStorageValue, getSessionStorageValue } from "../utils"
import { setSearchParams } from "../utils/urls"
import { isEmpty } from "lodash"

// Connects to data-controller="select-documents"
export default class extends Controller {
  static targets = ["form", "submitButton"]
  static values = {
    urlParam: { type: String, default: "record_id[]" },
    trackSubmitParamInUrl: { type: Boolean, default: false },
    submitParam: { type: String, default: "documents_to_copy[]" },
  }

  formTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement
  hasSubmitButtonTarget: boolean
  urlParamValue: string
  submitParamValue: string

  handleRowSelectionFunc: (e) => void

  connect() {
    this.handleSubmitButton()

    this.handleRowSelectionFunc = this.handleRowSelection.bind(this)
    document.addEventListener("BulkActions:recordSelected", this.handleRowSelectionFunc)
  }

  disconnect() {
    document.removeEventListener("BulkActions:recordSelected", this.handleRowSelectionFunc)
  }

  handleSubmitButton(): void {
    if (this.hasSubmitButtonTarget) {
      if (isEmpty(getSessionStorageValue("record_id"))) {
        disable(this.submitButtonTarget)
      } else {
        enable(this.submitButtonTarget)
      }
    } else {
      window.dispatchEvent(
        new CustomEvent("DocumentBulkEditForm:documentsSelected", {
          detail: { enable: !isEmpty(getSessionStorageValue("record_id")) },
        }),
      )
    }
  }

  handleRowSelection(e): void {
    const { recordId, isSelected } = e.detail

    this.updateURL(recordId, isSelected)
    this.handleSubmitButton()
  }

  updateURL(recordId, isSelected): void {
    const formUrl = new URL(this.formTarget.action)
    let originalQueryParams = new URLSearchParams(formUrl.search)

    if (isSelected) {
      if (!originalQueryParams.has(this.urlParamValue, recordId)) {
        originalQueryParams.append(this.urlParamValue, recordId)
      }
    } else {
      originalQueryParams.delete(this.urlParamValue, recordId)
    }

    const baseUrl = formUrl.origin + formUrl.pathname
    this.formTarget.action = `${baseUrl}?${originalQueryParams.toString()}`
  }

  submitForm(e): void {
    e.preventDefault()

    let formUrl = new URL(this.formTarget.action)
    let originalQueryParams = new URLSearchParams(formUrl.search)

    Array.from(getSessionStorageValue("record_id")).forEach((id) => {
      this.createInput(this.submitParamValue, id)
    })

    this.createInput("last_document_id", Array.from(getSessionStorageValue("record_id")).slice(-1)[0])

    // clear out passed-in and new params
    originalQueryParams.delete(this.urlParamValue)
    originalQueryParams.delete(this.submitParamValue)
    const baseUrl = formUrl.origin + formUrl.pathname
    this.formTarget.action = `${baseUrl}?${originalQueryParams.toString()}`
    setSearchParams(originalQueryParams)

    setSessionStorageValue("record_id", [])
    setSessionStorageValue("is_bulk_records_set", null)

    this.formTarget.requestSubmit()
  }

  createInput(name, value): void {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = name
    input.value = value
    this.formTarget.appendChild(input)
  }
}
