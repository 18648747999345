import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, removeElement, show } from "../../../utils"
import { kebabCase } from "lodash"

// Connects to data-controller="settings--custom-fields--form"

export default class extends Controller {
  static targets = [
    "appearsOnWrapper",
    "attachToRadioButton",
    "choicesWrapper",
    "fieldTypeSelect",
    "formBody",
    "confirmation",
    "form",
    "submitButton",
  ]

  static values = {
    fieldType: { type: String },
    isEdit: { type: Boolean },
  }

  appearsOnWrapperTargets: HTMLDivElement[]
  attachToRadioButtonTargets: HTMLInputElement[]
  choicesWrapperTarget: HTMLDivElement
  fieldTypeSelectTarget: HTMLSelectElement
  formBodyTarget: HTMLDivElement
  confirmationTarget: HTMLDivElement
  formTarget: HTMLFormElement
  submitButtonTarget: HTMLButtonElement

  fieldTypeValue: string
  isEditValue: Boolean

  connect() {
    this.onAttachedToSelected()
    this.onFieldTypeSelected()
  }

  onFieldTypeSelected(): void {
    const optionInputs = this.choicesWrapperTarget.querySelectorAll(
      "input[name='custom_field[choices_with_id][][choice_name]']",
    )
    const optionIdInputs = this.choicesWrapperTarget.querySelectorAll(
      "input[name='custom_field[choices_with_id][][choice_id]']",
    )
    const fieldType = this.fieldTypeSelectTarget.tomselect
      ? this.fieldTypeSelectTarget.tomselect.getValue()
      : this.fieldTypeValue

    if (["CustomFields::MultiSelect", "CustomFields::MultipleChoice"].includes(fieldType)) {
      show(this.choicesWrapperTarget)
      optionInputs.forEach((input: HTMLInputElement) => enable(input))
      optionIdInputs.forEach((input: HTMLInputElement) => enable(input))
    } else if (this.isEditValue) {
      removeElement(this.choicesWrapperTarget)
    } else {
      hide(this.choicesWrapperTarget)
      optionInputs.forEach((input: HTMLInputElement) => disable(input))
      optionIdInputs.forEach((input: HTMLInputElement) => disable(input))
    }
  }

  onAttachedToSelected(): void {
    const checkedAttachedToValue = Array.prototype.find.call(
      this.attachToRadioButtonTargets,
      (button) => button.checked,
    ).value

    const wrapperClass = `appears-on-options-for-${kebabCase(checkedAttachedToValue)}`

    this.appearsOnWrapperTargets.forEach((wrapper) => {
      const checkboxInputs = wrapper.querySelectorAll("input")

      if (wrapper.classList.contains(wrapperClass)) {
        show(wrapper)
        checkboxInputs.forEach((checkboxInput) => enable(checkboxInput))
      } else {
        hide(wrapper)
        checkboxInputs.forEach((checkboxInput) => disable(checkboxInput))
      }
    })
  }

  onChangesSave(e): void {
    e.preventDefault()

    if (this.formBodyTarget.classList.contains("hidden")) {
      this.formTarget.requestSubmit()
    } else {
      if (!this.formTarget.checkValidity()) {
        return
      }
      hide(this.formBodyTarget)
      show(this.confirmationTarget)

      const heading = document.querySelector("#modal-body .section-heading")
      heading.innerText = "Confirm Edit"
      this.submitButtonTarget.value = "Confirm Edit"
    }
  }

  updateChoiceId(e): void {
    const wrapper = e.target.closest(".clone-wrapper")
    const subtitle = wrapper.querySelector(".unique_id")
    subtitle.innerText = `Unique ID: ${e.target.value}`
    const hiddenField = wrapper.querySelector("input[name='custom_field[choices_with_id][][choice_id]']")
    hiddenField.value = e.target.value
  }

  showAlert(e): void {
    e.preventDefault()
    const wrapper = e.target.closest(".existing-choice-wrapper")
    const alert = wrapper.querySelector(".existing-choice-alert")
    show(alert)
  }
}
