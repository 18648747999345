import { Handle, Position, useNodes } from "reactflow"
import { useWorkflow } from "./workflowContext"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { AddFirstTaskButton } from "./addFirstTaskButton"
import React from "react"

const noTasks = (nodes) => nodes.filter((node) => node.type === "workflowTaskCard").length === 0

export const BillSubmittedCardNode = ({ data }) => {
  const nodes = useNodes()

  const { cardDimensions, editable } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `185px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className="pointer-events-auto z-2 p-4 border border-base rounded-md bg-white"
      style={containerStyles}
    >
      <div className="small-heading truncate mb-4">Bill Submitted for Approval</div>
      <div className="flex items-center">
        <div className="rounded-full bg-red w-4 h-4 flex items-center justify-center">
          <i className="fa-solid fa-caret-right"></i>
        </div>
        <p className="ml-4 body-text body-text--long">This workflow will start once bill is submitted for approval</p>
      </div>

      <Handle type="source" position={Position.Right} style={handleStyles} />
      {editable && data.addTaskPathRight && noTasks(nodes) && <AddFirstTaskButton actionPath={data.addTaskPathRight} />}
    </div>
  )
}
