import { Controller } from "@hotwired/stimulus"
import { hide, removeRequired, setRequired, show } from "../utils"

type FormElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement

const APPENDED_OPTIONAL_TEXT_CLASS = "APPENDED_OPTIONAL_TEXT"

export default class extends Controller {
  static targets = ["form", "questionsContainer", "uploadConfirmation", "uploadConfirmationAnswerChoice"]

  requiredFormFields: FormElement[] = []
  labelsMarkedAsRequired: HTMLLabelElement[] = []
  formTarget: HTMLFormElement
  questionsContainerTarget: HTMLDivElement
  uploadConfirmationTarget: HTMLDivElement
  uploadConfirmationAnswerChoiceTargets: HTMLInputElement[]

  clearFileFieldHiddenInputsFunction: () => void

  connect = () => {
    this.clearFileFieldHiddenInputsFunction = this.clearFileFieldHiddenInputs.bind(this)

    this.formTarget.addEventListener("submit", this.clearFileFieldHiddenInputsFunction)
  }

  disconnect() {
    this.formTarget.removeEventListener("submit", this.clearFileFieldHiddenInputsFunction)
  }

  aggregateRequiredFormFields = () => {
    const formFields = this.formTarget.elements
    const labels = this.formTarget.querySelectorAll<HTMLLabelElement>("label.section-heading")

    this.requiredFormFields = Array.from(formFields as FormElement[]).filter(
      (e) => e.required && !this.uploadConfirmationAnswerChoiceTargets.includes(e as HTMLInputElement),
    )

    this.labelsMarkedAsRequired = Array.from(labels).filter((e) => {
      return !e.innerText.toUpperCase().includes("(OPTIONAL)")
    })
  }

  clearFileFieldHiddenInputs = () => {
    const unusedFileNames = Array.from(this.questionsContainerTarget.querySelectorAll("input[name='file_name[]']"))
    const unusedUploadMethods = Array.from(
      this.questionsContainerTarget.querySelectorAll("input[name='upload_method[]']"),
    )

    unusedUploadMethods.concat(unusedFileNames).forEach((e) => {
      e.remove()
    })
  }

  hideUploadConfirmation = () => {
    hide(this.uploadConfirmationTarget)
    this.uploadConfirmationAnswerChoiceTargets.forEach((e) => {
      e.checked = false
      removeRequired(e)
    })
  }

  makeAllFormFieldsOptional = () => {
    this.requiredFormFields.forEach(removeRequired)
    this.labelsMarkedAsRequired.forEach((e) => {
      e.appendChild(this.optionalTextNode())
    })
  }

  makeOptionalFieldsRequiredAgain = () => {
    this.requiredFormFields.forEach(setRequired)
    this.labelsMarkedAsRequired.forEach((e) => {
      e.querySelector(`span.${APPENDED_OPTIONAL_TEXT_CLASS}`).remove()
    })

    this.requiredFormFields = []
    this.labelsMarkedAsRequired = []
  }

  notCorrectUploader = (e: Element) => !e.classList.contains("QUESTIONNAIRE_ALT_SUBMISSION")

  optionalTextNode = (): HTMLSpanElement => {
    const node = document.createElement("span")
    node.appendChild(document.createTextNode(" (Optional)"))
    node.classList.add(APPENDED_OPTIONAL_TEXT_CLASS)

    return node
  }

  relyOnFileForFormResponseAnswers = ({ detail }: CustomEvent) => {
    if (this.notCorrectUploader(detail.container)) return

    this.showUploadConfirmation()
    this.aggregateRequiredFormFields()
    this.makeAllFormFieldsOptional()
  }

  relyOnFormFieldsForFormResponseAnswers = ({ detail }: CustomEvent) => {
    if (this.notCorrectUploader(detail.container) || detail.file_length > 0) return

    this.hideUploadConfirmation()
    this.makeOptionalFieldsRequiredAgain()
  }

  showUploadConfirmation = () => {
    show(this.uploadConfirmationTarget)
    this.uploadConfirmationAnswerChoiceTargets.forEach(setRequired)
  }
}
