import { Controller } from "@hotwired/stimulus"

interface FrameElement extends HTMLElement {
  src: string
}

// Connects to data-controller="unisearch--search-icon"
export default class extends Controller {
  static targets = ["icon"]

  iconTarget: HTMLDivElement
  iconTargets: [HTMLDivElement]

  static values = {
    url: String,
  }

  urlValue: string

  iconUpdateOnSubmissionFunction = this.iconUpdateOnSubmission.bind(this)

  connect() {
    // Events based on form submission and request finish
    // https://turbo.hotwired.dev/reference/events
    document.addEventListener("turbo:submit-start", this.iconUpdateOnSubmissionFunction)
    document.addEventListener("turbo:submit-end", this.iconUpdateOnSubmissionFunction)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-start", this.iconUpdateOnSubmissionFunction)
    document.removeEventListener("turbo:submit-end", this.iconUpdateOnSubmissionFunction)
  }

  // Runs on form submission and network completion.
  iconUpdateOnSubmission({ type }) {
    switch (type) {
      case "turbo:submit-start":
        this.iconTargets.forEach((icon) => (icon.id === "spin" ? this.show(icon) : this.hide(icon)))
        break
      case "turbo:submit-end":
        this.iconTargets.forEach((icon) => (icon.id === "close" ? this.show(icon) : this.hide(icon)))
        break
      default:
        this.iconTargets.forEach((icon) =>
          icon.id === "arrow-turn-down-left-active" ? this.show(icon) : this.hide(icon),
        )
    }
  }

  // when "x" (close icon) is pressed the modal refreshes to its initial state
  reset() {
    const modalBodyFrame: FrameElement = document.querySelector("#modal-body")
    modalBodyFrame.src = `${this.urlValue}/new`
  }

  // Runs on any keystroke
  iconUpdate(e) {
    // Don't update on "Enter" key press
    if (e.key == "Enter") {
      return
    }
    const target_value = e.target.value.length

    if (target_value > 0) {
      this.iconTargets.forEach((icon) =>
        icon.id === "arrow-turn-down-left-active" ? this.show(icon) : this.hide(icon),
      )
    } else {
      this.iconTargets.forEach((icon) => (icon.id === "arrow-turn-down-left" ? this.show(icon) : this.hide(icon)))
    }
  }

  show(icon) {
    icon.classList.remove("hidden")
  }

  hide(icon) {
    icon.classList.add("hidden")
  }
}
