import React from "react"
import { Handle, Position, useNodes } from "reactflow"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { AddFirstTaskButton } from "./addFirstTaskButton"
import { AssignPurchaseCategoryCardNode } from "./assignPurchaseCategoryCardNode"
import { BillSubmittedCardNode } from "./billSubmittedCardNode"
import { EditIntakeFormCardNode } from "./editIntakeFormCardNode"
import { DateTriggerCardNode } from "./dateTriggerCardNode"

export const workflowRootTaskCardNode = (props) => {
  const { data } = props
  if (data?.objectEventType == "date.triggered") {
    return <DateTriggerCardNode {...props} />
  } else if (data?.objectEventType == "bill.created") {
    return <BillSubmittedCardNode {...props} />
  } else if (!data?.requestCategory) {
    return <AssignPurchaseCategoryCardNode {...props} />
  } else {
    return <EditIntakeFormCardNode {...props} />
  }
}

export default workflowRootTaskCardNode
