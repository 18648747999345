import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="wheel-scroll-disable"
export default class extends Controller {
  static targets = ["numberInput"]

  numberInputTarget: HTMLInputElement

  numberInputTargetConnected(input) {
    if (input.type === "number") {
      input.addEventListener("mousewheel", this.onMouseWheel(input))
    }
  }

  numberInputTargetDiscconnected(input) {
    if (input.type === "number") {
      input.removeEventListener("mousewheel", this.onMouseWheel(input))
    }
  }

  onMouseWheel(input) {
    return () => {
      input.blur()
    }
  }
}
