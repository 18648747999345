import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="platform-contract-form"
export default class extends Controller {
  // targets
  static targets = [
    "datesFieldsGrid",
    "generalFieldsGrid",
    "paymentFieldsGrid",
    "paymentMethodOther",
    "paymentMethodSelection",
    "paymentTermsOther",
    "paymentTermsSelection",
    "renewalReminderField",
    "sendRenewalReminderField",
    "triggerDateFieldContainer",
    "triggerDateFieldPlaceholder",
  ]
  static outlets = ["styled-select"]
  datesFieldsGridTarget: HTMLDivElement
  generalFieldsGridTarget: HTMLDivElement
  hasSendRenewalReminderFieldTarget: boolean
  paymentFieldsGridTarget: HTMLDivElement
  paymentMethodOtherTarget: HTMLDivElement
  paymentMethodSelectionTarget: HTMLSelectElement
  paymentTermsOtherTarget: HTMLDivElement
  paymentTermsSelectionTarget: HTMLSelectElement
  sendRenewalReminderFieldTarget: HTMLInputElement
  triggerDateFieldContainerTarget: HTMLElement
  triggerDateFieldPlaceholderTarget: HTMLElement

  hasDatesFieldsGrid: boolean
  hasGeneralFieldsGridTarget: boolean
  hasPaymentFieldsGrid: boolean

  onChangeSendRenewalReminderFieldTargetFunction = (e: Event) => {}

  // bind() creates a new function with the given context, we need to store the
  // reference of that function so we can then remove the listener
  toggleScreenLayoutHandler = this.toggleScreenLayout.bind(this)

  connect() {
    this.togglePaymentTermsOther()
    this.togglePaymentMethodOther()
    this.onChangeSendRenewalReminderFieldTargetFunction = (e) => {
      this.toggleTriggerDateFields((<HTMLInputElement>e.target).checked)
    }

    if (this.hasSendRenewalReminderFieldTarget) {
      this.sendRenewalReminderFieldTarget.addEventListener(
        "change",
        this.onChangeSendRenewalReminderFieldTargetFunction,
      )
      this.toggleTriggerDateFields(this.sendRenewalReminderFieldTarget.checked)
    }

    document.addEventListener("SlidingSidebar:onToggle", this.toggleScreenLayoutHandler)
    this.set2GridColumnsOnLg()
  }

  disconnect() {
    document.removeEventListener("SlidingSidebar:onToggle", this.toggleScreenLayoutHandler)

    if (this.hasSendRenewalReminderFieldTarget) {
      this.sendRenewalReminderFieldTarget.removeEventListener(
        "change",
        this.onChangeSendRenewalReminderFieldTargetFunction,
      )
    }
  }

  toggleTriggerDateFields(checked: boolean) {
    if (checked) {
      hide(this.triggerDateFieldContainerTarget)
      show(this.triggerDateFieldPlaceholderTarget)
    } else {
      show(this.triggerDateFieldContainerTarget)
      hide(this.triggerDateFieldPlaceholderTarget)
    }
  }

  togglePaymentTermsOther() {
    if (this.paymentTermsSelectionTarget.value === "other") {
      show(this.paymentTermsOtherTarget)
    } else {
      hide(this.paymentTermsOtherTarget)
    }
  }

  togglePaymentMethodOther() {
    if (this.paymentMethodSelectionTarget.value === "other") {
      show(this.paymentMethodOtherTarget)
    } else {
      hide(this.paymentMethodOtherTarget)
    }
  }

  toggleScreenLayout(event: CustomEvent) {
    const { open } = event.detail

    if (open) {
      document.dispatchEvent(new CustomEvent("DetailsLayout:stackColumns"))
      this.set2GridColumnsOnXl()
    } else {
      document.dispatchEvent(new CustomEvent("DetailsLayout:resetColumns"))
      this.set2GridColumnsOnLg()
    }
  }

  set2GridColumnsOnLg() {
    if (!this.hasGridTargets()) {
      return
    }

    this.generalFieldsGridTarget.classList.remove("xl:grid-cols-2")
    this.datesFieldsGridTarget.classList.remove("xl:grid-cols-2")
    this.paymentFieldsGridTarget.classList.remove("xl:grid-cols-2")

    this.generalFieldsGridTarget.classList.add("lg:grid-cols-2")
    this.datesFieldsGridTarget.classList.add("lg:grid-cols-2")
    this.paymentFieldsGridTarget.classList.add("lg:grid-cols-2")
  }

  set2GridColumnsOnXl() {
    if (!this.hasGridTargets()) {
      return
    }

    this.generalFieldsGridTarget.classList.add("xl:grid-cols-2")
    this.datesFieldsGridTarget.classList.add("xl:grid-cols-2")
    this.paymentFieldsGridTarget.classList.add("xl:grid-cols-2")

    this.generalFieldsGridTarget.classList.remove("lg:grid-cols-2")
    this.datesFieldsGridTarget.classList.remove("lg:grid-cols-2")
    this.paymentFieldsGridTarget.classList.remove("lg:grid-cols-2")
  }

  private hasGridTargets() {
    return this.hasGeneralFieldsGridTarget && this.hasDatesFieldsGrid && this.hasPaymentFieldsGrid
  }
}
