import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide, show } from "../utils"
import { DropzoneFile } from "dropzone"
import {
  EVENT_ADDING_EXISTING_DOCUMENT_RECORD,
  EVENT_FILE_ADDED,
  EVENT_FILE_REMOVED,
  FileChangeEventDetail,
} from "./docupload_controller"
import PanelAccordionGroupComponentController from "./canopy/panel_accordion_group_component_controller"
import DialogComponentController from "./canopy/dialog_component_controller"

export const ACTIVE_LIST_ITEM_CLASSES = ["active", "!border-purple-500", "bg-purple-100"]

// Connects to data-controller="document-uploader-component"
export default class extends Controller {
  static outlets = ["canopy--panel-accordion-group-component", "canopy--dialog-component"]

  static targets = [
    "form",
    "currentFileSignature",
    "documentsForm",
    "fileListItem",
    "hiddenCreatedDocumentListItem",
    "hiddenExistingDocumentListItem",
    "submitButton",
    "disabledSubmitButton",
  ]

  static values = {
    confirmSkipUploadDocuments: {
      default: false,
      type: Boolean,
    },
  }

  currentFileSignatureTarget: HTMLInputElement
  formTarget: HTMLFormElement
  documentsFormTarget: HTMLFormElement
  hiddenCreatedDocumentListItemTargets: HTMLInputElement[]
  hiddenExistingDocumentListItemTargets: HTMLInputElement[]
  submitButtonTarget: HTMLButtonElement
  disabledSubmitButtonTarget: HTMLButtonElement
  fileListItemTargets: HTMLElement[]
  files: Set<DropzoneFile> = new Set()
  filePanelOpenTriggerMap: Map<string, HTMLElement> = new Map()
  panelOpenTriggerFileMap: Map<HTMLElement, DropzoneFile> = new Map()
  savedAndUnsavedFilesMap: Map<string, string> = new Map()

  hasFormTarget: boolean
  hasSubmitButtonTarget: boolean
  hasDisabledSubmitButtonTarget: boolean
  hasCanopyDialogComponentOutlet: boolean

  confirmSkipUploadDocumentsValue: boolean

  canopyPanelAccordionGroupComponentOutlet: PanelAccordionGroupComponentController
  canopyDialogComponentOutlet: DialogComponentController

  submitHandler = this.submitForm.bind(this)

  addFile = (file: DropzoneFile, fileListItem: HTMLElement) => {
    this.files.add(file)
    this.linkFileToPanelTrigger(file, fileListItem)
    this.savedAndUnsavedFilesMap.set(file.signed_id, this.initialFilePersistenceStatus(file))
    this.disableEnableSubmitButton()
  }

  allFilesAreSaved = (): boolean => {
    return Array.from(this.savedAndUnsavedFilesMap.values()).every((value) => value === "saved")
  }

  connect = (): void => {
    window.addEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
    window.addEventListener(EVENT_FILE_REMOVED, this.handleFileRemoved)
    window.addEventListener("AccordionPanel:closed", this.deselectAllListItems)
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("submit", this.submitHandler)
    }
  }

  disableEnableSubmitButton = (): void => {
    if (!this.hasSubmitButtonTarget) return
    this.allFilesAreSaved() ? this.enableSubmit() : this.disableSubmit()
  }

  enableSubmit(): void {
    if (this.hasDisabledSubmitButtonTarget) {
      enable(this.submitButtonTarget)
      show(this.submitButtonTarget)
      hide(this.disabledSubmitButtonTarget)
    } else {
      enable(this.submitButtonTarget)
    }
  }

  disableSubmit(): void {
    if (this.hasDisabledSubmitButtonTarget) {
      disable(this.disabledSubmitButtonTarget)
      hide(this.submitButtonTarget)
      show(this.disabledSubmitButtonTarget)
    } else {
      disable(this.submitButtonTarget)
    }
  }

  disconnect = (): void => {
    window.removeEventListener(EVENT_FILE_ADDED, this.handleFileAdded)
    window.removeEventListener(EVENT_FILE_REMOVED, this.handleFileRemoved)
    window.removeEventListener("AccordionPanel:closed", this.deselectAllListItems)
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener("submit", this.submitHandler)
    }
  }

  handleFileAdded = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = { ...e.detail.file }
    const fileListItem = this.searchForFileListItem(file)
    this.addFile(file, fileListItem)
    if (!e.detail.fromSessionStorage) {
      fileListItem.click()
    }
  }

  handleFileRemoved = (e: CustomEvent<FileChangeEventDetail>): void => {
    const file = e.detail.file
    const fileListItem = this.filePanelOpenTriggerMap.get(file.signed_id)

    if (this.currentFileSignatureTarget.value === file.signed_id) {
      this.canopyPanelAccordionGroupComponentOutlet.hidePanelWithId("document-uploader-component__document-viewer")
      this.currentFileSignatureTarget.value = ""
    }

    this.files.delete(file)
    this.filePanelOpenTriggerMap.delete(file.signed_id)
    this.panelOpenTriggerFileMap.delete(fileListItem)
    this.savedAndUnsavedFilesMap.delete(file.signed_id)
    this.disableEnableSubmitButton()
    this.removeHiddenTrackedDocumentListItem(file)
  }

  hiddenExistingDocumentListItemTargetConnected = (element: HTMLInputElement) => {
    const fileInfo = JSON.parse(element.dataset.fileInfo)
    const sourceComponent = element.dataset.sourceComponent

    if (sourceComponent === "document-list-item") {
      const fileListItem = this.searchForFileListItem(fileInfo)
      this.addFile(fileInfo, fileListItem)
      return
    }

    setTimeout(() => {
      window.dispatchEvent(new CustomEvent(EVENT_ADDING_EXISTING_DOCUMENT_RECORD, { detail: fileInfo }))
    }, 0)
  }

  initialFilePersistenceStatus = (file: DropzoneFile): "saved" | "unsaved" => {
    const alreadySaved = !!this.hiddenExistingDocumentListItemTargets.find(
      (el) => el.dataset.signedId === file.signed_id,
    )
    return alreadySaved ? "saved" : "unsaved"
  }

  linkFileToPanelTrigger = (file: DropzoneFile, fileListItem: HTMLElement): void => {
    this.filePanelOpenTriggerMap.set(file.signed_id, fileListItem)
    this.panelOpenTriggerFileMap.set(fileListItem, file)
  }

  onDocumentSaved = (e): void => {
    const documentUpdateForm = e.currentTarget.form

    // set a timeout so form validations can run first
    setTimeout(() => {
      const currentFile = Array.from(this.files).find(
        (file) => file.signed_id === this.currentFileSignatureTarget.value,
      )

      // checkValidity will return false if the form is no longer present on the screen
      if (
        (documentUpdateForm.checkValidity() && documentUpdateForm.checkVisibility()) ||
        !documentUpdateForm.checkVisibility()
      ) {
        this.savedAndUnsavedFilesMap.set(currentFile.signed_id, "saved")
      }
      this.disableEnableSubmitButton()
    }, 300)
  }

  searchForFileListItem = (file: DropzoneFile): Element | undefined => {
    return this.fileListItemTargets.find((el) => el.dataset.uniqueId === file.upload.uuid)
  }

  showLinkedFileInDynamicPanel = (e: MouseEvent): void => {
    const fileListItem = e.currentTarget as HTMLElement
    const fileSignature = fileListItem.dataset.signedId

    this.deselectAllListItems()
    fileListItem.classList.add(...ACTIVE_LIST_ITEM_CLASSES)

    if (this.currentFileSignatureTarget.value === fileSignature) return

    this.currentFileSignatureTarget.value = fileSignature
    this.documentsFormTarget.requestSubmit()
  }

  deselectAllListItems = () => {
    this.element.querySelectorAll(".document-list-item.active").forEach((el: HTMLInputElement) => {
      el.classList.remove(...ACTIVE_LIST_ITEM_CLASSES)
    })
  }

  private submitForm(e) {
    if (
      this.savedAndUnsavedFilesMap.size === 0 &&
      this.confirmSkipUploadDocumentsValue &&
      this.hasCanopyDialogComponentOutlet
    ) {
      e.preventDefault()
      this.canopyDialogComponentOutlet.open()
    }
  }

  private removeHiddenTrackedDocumentListItem(file: DropzoneFile): void {
    this.hiddenCreatedDocumentListItemTargets.forEach((el: HTMLInputElement): void => {
      if (el.dataset.signedId === file.signed_id) {
        el.remove()
      }
    })

    this.hiddenExistingDocumentListItemTargets.forEach((el: HTMLInputElement): void => {
      if (el.dataset.signedId === file.signed_id) {
        el.remove()
      }
    })
  }
}
