import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inbox--item-select"
export default class extends Controller {
  static targets = ["invoicesInboundEmail"]
  invoicesInboundEmailTargets: HTMLDivElement[]

  click(e): void {
    this.invoicesInboundEmailTargets.forEach((el) => {
      el.classList.remove("bg-purple-100", "border-l-purple-500")
      el.classList.add("bg-white", "border-l-white")
    })

    e.currentTarget.classList.remove("bg-white", "border-l-white")
    e.currentTarget.classList.add("bg-purple-100", "border-l-purple-500")
  }
}
