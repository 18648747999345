import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js"

// Connects to data-controller="settings--ai-two-way-match--setup"
export default class extends Controller {
  static targets = ["input", "url"]
  static values = { url: String }

  urlValue: string
  inputTarget: HTMLInputElement
  typingTimer: NodeJS.Timeout

  DEBUOUNCE_TIME = 500

  connect() {
    console.log("settings--ai-two-way-match--setup")
    this.typingTimer = null
  }

  validate_ap_email() {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(() => {
      this.fetchResults()
    }, this.DEBUOUNCE_TIME)
  }

  async fetchResults() {
    const ap_inbox_email = this.inputTarget.value

    post(this.urlValue, { body: { ap_inbox_email: ap_inbox_email }, responseKind: "turbo-stream" })
  }
}
