import { Controller } from "@hotwired/stimulus"
import { hide } from "../utils"

// Connects to data-controller="rich-text-area"
export default class extends Controller {
  static targets = ["editorArea", "outerContainer", "wrapElement"]
  editorAreaTarget: HTMLInputElement
  outerContainerTarget: HTMLDivElement
  wrapElementTarget: HTMLDivElement

  static values = {
    disabled: Boolean,
    required: Boolean,
  }

  disabledValue: boolean
  requiredValue: boolean

  trix: any
  editor: any
  validationInput: HTMLInputElement

  onTrixInitializeFunc = this.onTrixInitialize.bind(this)
  onTrixChangeFunc = this.onTrixChange.bind(this)
  onWrapElementTargetClickFunc = this.onWrapElementTargetClick.bind(this)

  connect() {
    this.outerContainerTarget.addEventListener("trix-initialize", this.onTrixInitializeFunc)
  }

  disconnect() {
    this.outerContainerTarget.removeEventListener("trix-initialize", this.onTrixInitializeFunc)
    this.outerContainerTarget.removeEventListener("trix-change", this.onTrixChangeFunc)
    this.wrapElementTarget.removeEventListener("click", this.onWrapElementTargetClickFunc)
  }

  onTrixInitialize(e) {
    this.onInitialize((e.target as any)?.value)
  }

  onTrixChange(e) {
    this.onChange((e.target as any)?.value)
  }

  onWrapElementTargetClick(e) {
    this.focusEditor(e)
  }

  focusEditor(e) {
    let buttonRow = this.wrapElementTarget.querySelector(".trix-button-row")
    let spacer = this.wrapElementTarget.querySelector(".trix-button-group-spacer")
    let hrefInput = this.wrapElementTarget.querySelector("[data-comment-form-target='hrefInput']")

    if (e.target !== buttonRow && e.target !== spacer && buttonRow.contains(e.target)) {
      return
    }
    if (e.target === hrefInput) {
      return
    }

    this.trix.focus()
  }

  onInitialize(value: string) {
    this.setTrixAndEditor()
    this.setValidationInput()

    this.outerContainerTarget.addEventListener("trix-change", this.onTrixChangeFunc)
    this.wrapElementTarget.addEventListener("click", this.onWrapElementTargetClickFunc)

    this.synchronizeValidationInput(value)
    this.handleDisabledValue()
    this.handleRequiredValue()
  }

  onChange(value: string) {
    this.synchronizeValidationInput(value)
    this.checkValidity(value)
  }

  setTrixAndEditor() {
    this.trix = this.outerContainerTarget.querySelector("trix-editor")
    this.editor = this.trix.editor
  }

  setValidationInput() {
    this.validationInput = this.appendValidationInput()
  }

  handleDisabledValue() {
    if (this.disabledValue) {
      this.editor.element.setAttribute("contentEditable", false)
      this.outerContainerTarget.querySelector("trix-toolbar").classList.add("hidden")
      this.outerContainerTarget
        .querySelector("trix-editor")
        .setAttribute(
          "style",
          "cursor: not-allowed; border-color: rgb(203 213 224/var(--tw-border-opacity)); background-color: rgb(247 250 252/var(--tw-bg-opacity));",
        )
    }
  }

  handleRequiredValue() {
    if (this.requiredValue) {
      this.validationInput.setAttribute("required", "")
    }
  }

  checkValidity(value: string) {
    if (this.requiredValue && this.isEmpty(value)) {
      this.validationInput.setCustomValidity("required")
    } else {
      this.validationInput.setCustomValidity("")
    }

    this.validationInput.reportValidity()
  }

  stripImageTags(e) {
    // replace pasted html -> images with the source url for the image
    // figure contains the trix-attachment
    Array.from(this.editorAreaTarget.querySelectorAll("figure")).map((figure: HTMLElement) => {
      const image = figure.querySelector("img")
      if (!image) return

      const image_url = image.src
      if (!image_url) return

      const node = document.createElement("div")
      // We don't currently support local image pasting so only add the image url if it has `http` in it.
      node.innerHTML = image_url.includes("http") ? `${image_url}` : ""
      figure.replaceWith(node)
    })
  }

  private synchronizeValidationInput(value: string) {
    this.validationInput.value = this.stripHtmlTags(value)
  }

  private stripHtmlTags(text: string) {
    return text
      .replace(/<[^>]*>?/gm, "")
      .replace("&nbsp;", "")
      .trim()
  }

  private isEmpty(text: string) {
    return this.stripHtmlTags(text).length === 0
  }

  private appendValidationInput() {
    const input = document.createElement("input")
    input.setAttribute("id", `${this.trix.id}_validation`)
    input.setAttribute("type", "text")
    hide(input)

    this.wrapElementTarget.appendChild(input)
    return input
  }
}
