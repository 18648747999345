import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="canopy--content-switcher-component"
export default class extends Controller {
  static targets = ["tab", "content"]

  showContent(event) {
    const currentContentId = event.params.contentId

    // hide all content elements
    this.contentTargets.map((element) => hide(element))

    // show the selected content element
    let currentContentElement = this.contentTargets.find((element) => element.id === currentContentId)
    show(currentContentElement)
  }
}
