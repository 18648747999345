import { Controller } from "@hotwired/stimulus"
import { disable, enable, hide } from "../utils"

export default class extends Controller {
  static targets = ["btn", "error", "form", "text"]
  btnTarget: HTMLButtonElement
  errorTarget: HTMLElement
  textTarget: HTMLDivElement
  formTarget: HTMLFormElement
  hasBtnTarget: boolean
  hasFormTarget: boolean
  hasErrorTarget: boolean
  hasTextTarget: boolean

  buttonState = {
    style: { height: "", width: "", justifyContent: "" },
    innerHTML: "",
  }
  spinnerIcon = document.createElement("i")

  connect = () => {
    if (this.hasBtnTarget) {
      this.storeInitialButtonState()
      this.listenerElement.addEventListener("turbo:submit-end", this.resetSubmitButtonAfterRequest)
    }
    this.spinnerIcon.className = "fas fa-spinner fa-spin"
  }

  disconnect = () => {
    if (this.hasBtnTarget) {
      this.listenerElement.removeEventListener("turbo:submit-end", this.resetSubmitButtonAfterRequest)
    }
  }

  btnTargetConnected = () => {
    this.storeInitialButtonState()
  }

  get listenerElement() {
    return this.hasFormTarget ? this.formTarget : window
  }

  formIsValid = (): boolean => {
    return !!this.relevantFormTarget()?.reportValidity()
  }

  handleFieldChange() {
    if (this.hasErrorTarget && !this.errorTarget.classList.contains("hidden")) {
      hide(this.errorTarget)
    }
  }

  relevantFormTarget = (): HTMLFormElement | undefined => {
    if (this.hasFormTarget) return this.formTarget
    if (this.hasBtnTarget) return this.btnTarget.form
    return undefined
  }

  resetSubmitButton = () => {
    if (!this.hasBtnTarget) return
    if (this.btnTarget.contains(this.spinnerIcon)) {
      this.btnTarget.removeChild(this.spinnerIcon)
    }

    enable(this.btnTarget)
    this.btnTarget.style.height = this.buttonState.style.height
    this.btnTarget.style.width = this.buttonState.style.width
    this.btnTarget.style.justifyContent = this.buttonState.style.justifyContent
    this.btnTarget.innerHTML = this.buttonState.innerHTML
  }

  resetSubmitButtonAfterRequest = (e: CustomEvent) => {
    if (!this.relevantFormTarget()) return
    if (this.relevantFormTarget() !== e.target) return

    this.resetSubmitButton()
  }

  showSpinner = () => {
    if (this.hasBtnTarget) {
      disable(this.btnTarget)

      // Avoids collapse when clearing text by setting initial dimensions
      this.btnTarget.style.height = `${this.btnTarget.offsetHeight}px`
      this.btnTarget.style.width = `${this.btnTarget.offsetWidth}px`
      this.btnTarget.style.justifyContent = "center"
    }

    if (this.hasTextTarget) {
      this.textTarget.innerText = ""
      this.btnTarget.appendChild(this.spinnerIcon)
    }
  }

  showSpinnerAndSubmit = () => {
    this.showSpinner()
    this.submitForm()
  }

  storeInitialButtonState = () => {
    this.buttonState.style.height = this.btnTarget.style.height
    this.buttonState.style.width = this.btnTarget.style.width
    this.buttonState.style.justifyContent = this.btnTarget.style.justifyContent
    this.buttonState.innerHTML = this.btnTarget.innerHTML
  }

  // @deprecated Does not actually submit the form
  // @use `showSpinner`
  submit(): void {
    this.showSpinner()
  }

  submitForm(): void {
    if (this.formIsValid()) {
      this.relevantFormTarget()?.requestSubmit()
      return
    }

    this.resetSubmitButton()
  }
}
