import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="truncate-tooltip-component"
export default class extends Controller {
  static targets = ["content"]

  contentTarget: HTMLDivElement
  hasContentTarget: boolean

  connect() {
    this.disableTooltip()
  }

  disableTooltip() {
    const tooltipElement = this.element.querySelector<HTMLDivElement>('[data-popper-target="element"]')
    if (tooltipElement && !this.isTextTruncated()) {
      tooltipElement.classList.add("pointer-events-none")
    }
  }

  isTextTruncated() {
    if (!this.hasContentTarget) {
      return false
    }
    return this.contentTarget.offsetWidth < this.contentTarget.scrollWidth
  }
}
