import { Controller } from "@hotwired/stimulus"
import throttle from "lodash/throttle"

// Connects to data-controller="sidenav-collapse"
export default class SidenavCollapseController extends Controller {
  SIDENAV_STORAGE = "sidenavState"

  static targets = ["wrapper", "navigation", "spanHolder", "spanTop", "spanBottom", "fullLogo", "smallLogo"]

  wrapperTarget: HTMLElement
  spanHolderTarget: HTMLElement
  spanTopTarget: HTMLElement
  spanBottomTarget: HTMLElement
  fullLogoTarget: HTMLElement
  smallLogoTarget: HTMLElement

  // Property to store the previous state
  private wasCollapsedBeforeSettings: boolean | null = null

  handleResizeFunc = throttle(this.handleResize.bind(this), 100)

  connect(): void {
    if (this.hasWrapperTarget) {
      this.initializeSidebarState()

      this.handleResize()
      window.addEventListener("resize", this.handleResizeFunc)
    }
  }

  disconnect(): void {
    window.removeEventListener("resize", this.handleResizeFunc)
  }

  // Handles the resize event and collapses the sidebar if the window is too small
  handleResize(): void {
    const storedState = localStorage.getItem(this.SIDENAV_STORAGE)

    if (storedState === "collapsed" || window.innerWidth < 1200) {
      if (!this.wrapperTarget.classList.contains("collapsed-canopy-sidebar")) {
        this.collapseSidebar()
      }
    } else {
      if (
        this.wrapperTarget.classList.contains("collapsed-canopy-sidebar") &&
        this.wasCollapsedBeforeSettings === null
      ) {
        this.expandSidebar()
      }
    }
  }

  // Toggles the sidebar by adding or removing the collapsed-canopy-sidebar class
  toggleSidebar(): void {
    this.toggleClass(this.wrapperTarget, "collapsed-canopy-sidebar")
    this.updateUI()
    this.saveSidebarState()
  }

  // Update the UI based on the sidebar state
  updateUI(): void {
    const isCollapsed = this.wrapperTarget.classList.contains("collapsed-canopy-sidebar")
    this.updateTooltip(isCollapsed)
    this.updateArrows(isCollapsed)
    this.updateLogoVisibility(isCollapsed)
  }

  // Updates the tooltip of the sidenav_collapse component based on the sidebar state
  updateTooltip(isCollapsed: boolean): void {
    // Targeting the div directly as the tooltip is not a direct child of the sidenav-collapse-button
    const tooltipElement = document.querySelector("#sidenav-collapse-button #tooltip")
    if (tooltipElement) {
      tooltipElement.textContent = isCollapsed ? "Expand Nav" : "Collapse Nav"
    }
  }

  // Animates the arrows of the sidenav_collapse component based on the sidebar state
  updateArrows(isCollapsed: boolean): void {
    // Makes the arrows go west when the sidebar is expanded
    this.toggleClass(this.spanHolderTarget, "cursor-w-resize", !isCollapsed)
    this.toggleClass(this.spanTopTarget, "group-hover/arrows:rotate-[15deg]", !isCollapsed)
    this.toggleClass(this.spanBottomTarget, "group-hover/arrows:rotate-[-15deg]", !isCollapsed)
    // Makes the arrows go east when the sidebar is collapsed
    this.toggleClass(this.spanHolderTarget, "cursor-e-resize", isCollapsed)
    this.toggleClass(this.spanTopTarget, "group-hover/arrows:rotate-[-15deg]", isCollapsed)
    this.toggleClass(this.spanBottomTarget, "group-hover/arrows:rotate-[15deg]", isCollapsed)
  }

  toggleClass(element: HTMLElement, className: string, condition?: boolean): void {
    if (condition === undefined) {
      element.classList.toggle(className)
    } else {
      if (condition) {
        element.classList.add(className)
      } else {
        element.classList.remove(className)
      }
    }
  }

  // Initializes the sidebar state based on the current page
  initializeSidebarState(): void {
    const isCurrentlyCollapsed = this.wrapperTarget.classList.contains("collapsed-canopy-sidebar")

    if (this.shouldAutoCollapseSidebar()) {
      // If the user is in the settings page then sidebar should be collapsed
      this.wasCollapsedBeforeSettings = isCurrentlyCollapsed
      if (!isCurrentlyCollapsed) {
        this.collapseSidebar()
      }
    } else {
      // If the user is not in the settings page then sidebar should be set based on the cookie
      if (this.wasCollapsedBeforeSettings !== null) {
        if (this.wasCollapsedBeforeSettings) {
          this.collapseSidebar()
        } else {
          this.expandSidebar()
        }
        this.wasCollapsedBeforeSettings = null
      } else {
        this.setSidebarStateBasedOnLocalStorage()
      }
    }
    this.updateUI()
  }

  // Returns true if the user is in the settings page and the sidebar should be collapsed
  shouldAutoCollapseSidebar(): boolean {
    const pathname = window.location.pathname
    return (
      (pathname.includes("/settings") && !pathname.includes("/admin/settings")) ||
      pathname.includes("supplier_intelligence/suppliers/")
    )
  }

  // Expands the sidebar
  expandSidebar(): void {
    this.wrapperTarget.classList.remove("collapsed-canopy-sidebar")
    this.updateUI()
  }

  // Collapses the sidebar
  collapseSidebar(): void {
    this.wrapperTarget.classList.add("collapsed-canopy-sidebar")
    this.updateUI()
  }

  // Update the logo visibility based on the sidebar state// Update the logo visibility based on the sidebar state
  updateLogoVisibility(isCollapsed: boolean): void {
    this.toggleClass(this.fullLogoTarget, "block", !isCollapsed)
    this.toggleClass(this.fullLogoTarget, "hidden", isCollapsed)
    this.toggleClass(this.smallLogoTarget, "block", isCollapsed)
    this.toggleClass(this.smallLogoTarget, "hidden", !isCollapsed)
  }

  // Sets the sidebar state based on local storage
  setSidebarStateBasedOnLocalStorage(): void {
    const storedState = localStorage.getItem(this.SIDENAV_STORAGE)
    if (storedState === "collapsed") {
      this.collapseSidebar()
    } else {
      this.expandSidebar()
    }
  }

  // Saves the sidebar state to local storage
  saveSidebarState(): void {
    const isSidebarCollapsed = this.wrapperTarget.classList.contains("collapsed-canopy-sidebar")
    const sidebarState = isSidebarCollapsed ? "collapsed" : "expanded"
    localStorage.setItem(this.SIDENAV_STORAGE, sidebarState)
  }
}
